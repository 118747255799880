.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @extend .abs-action-addto-product;
    }
}

#wishlist-view-form .actions-toolbar {
    margin: 0;

    .action {
        width: auto;

        &.share {
            @extend .action.secondary;
        }
        &.tocart {
            @extend .action.primary;
        }
        &.back {
            @extend .action.default;
        }
    }
}

.product-info-main,
.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            text-transform: none;
            color: #2b0f1f;
            @include lib-font-size(18px);
            font-weight: normal;
            line-height: 46px;

            &:before {
                @extend .fas;
                @include fa-icon;
                content: fa-content($fa-var-heart);
                font-weight: 400;
                margin-right: $indent__xs;
            }

            &:hover {
                text-decoration: none;
                color: #da7a85;

                &:before {
                    font-weight: 900;
                }
            }
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }
    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }
    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }
    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }
    .col {
        &.item {
            width: 50%;
        }
        &.photo {
            max-width: 150px;
        }
        &.selector {
            max-width: 15px;
        }
    }
    textarea {
        margin: $indent__s 0;
    }
    .input-text.qty {
        margin-bottom: $indent__s;
    }
    .action.primary {
        vertical-align: top;
    }
    .price {
        font-weight: $font-weight__bold;
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }
    .counter {
        @extend .abs-block-items-counter;
    }
    .product-item-name {
        margin-right: $indent__m;
    }
}
.wishlist-index-index{
    .item.product.product-item{
        .product-item-inner > .product-item-actions{
            position: absolute;
            top: 10px;
            z-index: 1;
            font-size: 20px;

            a{
                margin-right:10px;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

//
//  Mixins
//  ____________________________________________
.ambase-word-break {
    word-wrap: break-word;
    word-break: break-word;

    .ie11 & {
        word-break: break-all;
    }
}

//
//  Utils
//  _____________________________________

.am-word-break {
    & {
        word-wrap: break-word;
        word-break: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}

.amscroll-arrow-icon {
    width: 14px;
    height: 28px;
    background: url('../Amasty_Scroll/images/arrow.svg');
}

.amscroll-chevron-icon {

}

@-webkit-keyframes amScrollRotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

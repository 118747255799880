//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

    .checkout-payment-method{
        width: 100%;
    }

    #customer-email-fieldset{
        margin-bottom:0;
    }
    .validator.validator-phone.validation-yes .feedback,
    .validator.validator-email.validation-yes .feedback {
        background-color: #0ca682;
        padding: 0;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-image: url(//services.postcodeanywhere.co.uk/images/Platform/Email/tick.png);
        background-repeat: no-repeat;
        background-position: center center;
        height: 20px !important;
        width: 20px !important;
        background-size: 10px !important;
        top: 10px !important;
        margin-right: 5px;
    }
    .validator.validator-phone.validation-no .feedback,
    .validator.validator-email.validation-no .feedback {
        background-color: #e53a43;
        padding: 0;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-image: url(//services.postcodeanywhere.co.uk/images/Platform/Email/cross.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 10px !important;
        height: 25px !important;
        width: 25px !important;
        top: 9px !important;
    }
    .validator.validator-email {
        height: auto !important;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }

    .logo {
        margin-left: 0;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;



    }

    #checkout-step-shipping{
        .form.form-shipping-address{
            max-width: 100%;
            width: 100%;
        }
    }
    #shipping-new-address-form{
        display: flex;
        flex-wrap: wrap;

        & > div {
            width: 50%;
        }
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}

//
//  Typography
//  ---------------------------------------------

.text-left{ text-align: left; }
.text-right{ text-align: right; }
.text-center{ text-align: center; }

.secondary-color{
    color:$secondary__color;
}
.items {
    @include lib-list-reset-styles();
}

cite {
    font-style: italic;
}

.help-block {
    display: block;
    margin-top: $indent__xs;
}

.ic {
    float: left;
    background-image: url('../images/wf_symbols.png');
    width: 44px;
    height: 49px;
    margin-right: $indent__s;

    &.ic-harsh {
        background-position: 0 0;
    }
    &.ic-equipment {
        background-position: 0 -53px;
    }
    &.ic-residual {
        background-position: -56px 0;
    }
    &.ic-shelf {
        background-position: -56px -53px;
    }
    & + span {
        display: block;
        line-height: 49px;
    }
}


p{
    font-size:15px;
    font-weight: 300;
}
@include max-screen($screen__s) {
    table {
        & > tbody,
        & > tfoot,
        & > thead {
            & > tr {
                & > td,
                & > th {
                    padding: 5px 10px 5px 0;
                }
            }
        }
    }
}

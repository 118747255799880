[data-content-type='row'][data-appearance='full-width'] {
    box-sizing: border-box;

    & > .row-full-width-inner {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $screen__l;
        width: 100%;
    }
}

@include max-screen($screen__m) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important;
    }
}
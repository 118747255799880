//
//  Buttons
//  ---------------------------------------------

button.action,
a.action,
span.action {
    @include lib-css(transition, all 0.2s);
    white-space: nowrap;

    .fas:first-child {
        margin-right: $indent__xs;
    }
    .fas:last-child {
        margin-left: $indent__xs;
    }
}


//
//  Default Button
//  ---------------------------------------------

$button__color                                  : $white__color;
$button__background                             : transparent;
$button__border                                 : 2px solid $white__color;

$button__hover__color                           : $button__color;
$button__hover__background                      : $button__background;
$button__hover__border                          : $button__border;

$button__active__color                          : $button__hover__color;
$button__active__background                     : $button__hover__background;
$button__active__border                         : $button__hover__border;

//
//  Default Button
//  ---------------------------------------------

.action.default {
    @include lib-button(
        $_button-color                          : $button__color,
        $_button-background                     : $button__background,
        $_button-border                         : $button__border,
        $_button-color-hover                    : $button__hover__color,
        $_button-background-hover               : $button__hover__background,
        $_button-border-hover                   : $button__hover__border,
        $_button-color-active                   : $button__active__color,
        $_button-background-active              : $button__active__background,
        $_button-border-active                  : $button__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.default {
    @include lib-link-as-button();
}

//
//  Primary Button Variables
//  ---------------------------------------------

$button-primary__color                          : $white__color;
$button-primary__background                     : $secondary__color;
$button-primary__border                         : 1px solid $secondary__color;

$button-primary__hover__color                   : $button-primary__color;
$button-primary__hover__background              : $button-primary__background;
$button-primary__hover__border                  : $button-primary__border;

$button-primary__active__color                  : $button-primary__hover__color;
$button-primary__active__background             : $button-primary__hover__background;
$button-primary__active__border                 : $button-primary__hover__border;

//
//  Primary Button
//  ---------------------------------------------

.action.primary {
    @include lib-button(
        $_button-color                         : $button-primary__color,
        $_button-background                    : $secondary__color,
        $_button-border                        : $button-primary__border,
        $_button-color-hover                   : $button-primary__hover__color,
        $_button-background-hover              : $button-primary__hover__background,
        $_button-border-hover                  : $button-primary__hover__border,
        $_button-color-active                  : $button-primary__active__color,
        $_button-background-active             : $button-primary__active__background,
        $_button-border-active                 : $button-primary__active__border
    );
    @include lib-css(border-radius, $button__border-radius);
}

.action.primary.border {
    background: $button-primary__color;
    border-color: $button-primary__background;
    color: $button-primary__background;
}

a.action.primary {
    @include lib-link-as-button();
}

//
//  Secondary Button Variables
//  ---------------------------------------------

$button-secondary__color                        : #253b4b;
$button-secondary__background                   : #eeeeee;
$button-secondary__border                       : 1px solid #eeeeee;

$button-secondary__hover__color                 : $button-secondary__color;
$button-secondary__hover__background            : $button-secondary__background;
$button-secondary__hover__border                : $button-secondary__border;

$button-secondary__active__color                : $button-secondary__hover__color;
$button-secondary__active__background           : $button-secondary__hover__background;
$button-secondary__active__border               : $button-secondary__hover__border;

//
//  Secondary Button
//  ---------------------------------------------

.action.secondary {
    @include lib-button(
        $_button-color                          : $button-secondary__color,
        $_button-background                     : $button-secondary__background,
        $_button-border                         : $button-secondary__border,
        $_button-color-hover                    : $button-secondary__hover__color,
        $_button-background-hover               : $button-secondary__hover__background,
        $_button-border-hover                   : $button-secondary__hover__border,
        $_button-color-active                   : $button-secondary__active__color,
        $_button-background-active              : $button-secondary__active__background,
        $_button-border-active                  : $button-secondary__active__border
    );
    @include lib-css(border-radius, $button__border-radius);
}

a.action.secondary {
    @include lib-link-as-button();
}


//
//  Colour Buttons
//  ---------------------------------------------

//
//  White Button Variables
//  ---------------------------------------------

$button-white__color                            : $green-dark__color;
$button-white__background                       : $white__color;
$button-white__border                           : #d0d0d0 1px solid;

$button-white__hover__color                     : $button-white__color;
$button-white__hover__background                : $button-white__background;
$button-white__hover__border                    : $button-white__border;

$button-white__active__color                    : $button-white__hover__color;
$button-white__active__background               : $button-white__hover__background;
$button-white__active__border                   : $button-white__hover__border;

//
//  White Button
//  ---------------------------------------------

.action.white {
    @include lib-button(
        $_button-color                          : $button-white__color,
        $_button-background                     : $button-white__background,
        $_button-border                         : $button-white__border,
        $_button-color-hover                    : $button-white__hover__color,
        $_button-background-hover               : $button-white__hover__background,
        $_button-border-hover                   : $button-white__hover__border,
        $_button-color-active                   : $button-white__active__color,
        $_button-background-active              : $button-white__active__background,
        $_button-border-active                  : $button-white__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.white {
    @include lib-link-as-button();
}

//
//  Black Button Variables
//  ---------------------------------------------

$button-black__color                            : $white__color;
$button-black__background                       : $black__color;
$button-black__border                           : $black__color;

$button-black__hover__color                     : $button-black__color;
$button-black__hover__background                : $button-black__background;
$button-black__hover__border                    : $button-black__border;

$button-black__active__color                    : $button-black__hover__color;
$button-black__active__background               : $button-black__hover__background;
$button-black__active__border                   : $button-black__hover__border;

//
//  Black Button
//  ---------------------------------------------

.action.black {
    @include lib-button(
        $_button-color                          : $button-black__color,
        $_button-background                     : $button-black__background,
        $_button-border                         : $button-black__border,
        $_button-color-hover                    : $button-black__hover__color,
        $_button-background-hover               : $button-black__hover__background,
        $_button-border-hover                   : $button-black__hover__border,
        $_button-color-active                   : $button-black__active__color,
        $_button-background-active              : $button-black__active__background,
        $_button-border-active                  : $button-black__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.black {
    @include lib-link-as-button();

}

//
//  gray Button Variables
//  ---------------------------------------------

$button-gray__color                            : $white__color;
$button-gray__background                       : $gray__color;
$button-gray__border                           : $gray__color;

$button-gray__hover__color                     : $button-gray__color;
$button-gray__hover__background                : $button-gray__background;
$button-gray__hover__border                    : $button-gray__border;

$button-gray__active__color                    : $button-gray__hover__color;
$button-gray__active__background               : $button-gray__hover__background;
$button-gray__active__border                   : $button-gray__hover__border;

//
//  gray Button
//  ---------------------------------------------

.action.gray {
    @include lib-button(
        $_button-color                          : $button-gray__color,
        $_button-background                     : $button-gray__background,
        $_button-border                         : $button-gray__border,
        $_button-color-hover                    : $button-gray__hover__color,
        $_button-background-hover               : $button-gray__hover__background,
        $_button-border-hover                   : $button-gray__hover__border,
        $_button-color-active                   : $button-gray__active__color,
        $_button-background-active              : $button-gray__active__background,
        $_button-border-active                  : $button-gray__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.gray {
    @include lib-link-as-button();
}

//
//  blue Button Variables
//  ---------------------------------------------

$button-blue__color                            : #3e3d30;
$button-blue__background                       : $blue__color;
$button-blue__border                           : $blue__color;

$button-blue__hover__color                     : $button-blue__color;
$button-blue__hover__background                : $button-blue__background;
$button-blue__hover__border                    : $button-blue__border;

$button-blue__active__color                    : $button-blue__hover__color;
$button-blue__active__background               : $button-blue__hover__background;
$button-blue__active__border                   : $button-blue__hover__border;

//
//  blue Button
//  ---------------------------------------------

.action.blue {
    @include lib-button(
        $_button-color                          : $button-blue__color,
        $_button-background                     : $button-blue__background,
        $_button-border                         : $button-blue__border,
        $_button-color-hover                    : $button-blue__hover__color,
        $_button-background-hover               : $button-blue__hover__background,
        $_button-border-hover                   : $button-blue__hover__border,
        $_button-color-active                   : $button-blue__active__color,
        $_button-background-active              : $button-blue__active__background,
        $_button-border-active                  : $button-blue__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.blue {
    @include lib-link-as-button();
}

//
//  red Button Variables
//  ---------------------------------------------

$button-red__color                            : $white__color;
$button-red__background                       : $red__color;
$button-red__border                           : $red__color;

$button-red__hover__color                     : $button-red__color;
$button-red__hover__background                : $button-red__background;
$button-red__hover__border                    : $button-red__border;

$button-red__active__color                    : $button-red__hover__color;
$button-red__active__background               : $button-red__hover__background;
$button-red__active__border                   : $button-red__hover__border;

//
//  red Button
//  ---------------------------------------------

.action.red {
    @include lib-button(
        $_button-color                          : $button-red__color,
        $_button-background                     : $button-red__background,
        $_button-border                         : $button-red__border,
        $_button-color-hover                    : $button-red__hover__color,
        $_button-background-hover               : $button-red__hover__background,
        $_button-border-hover                   : $button-red__hover__border,
        $_button-color-active                   : $button-red__active__color,
        $_button-background-active              : $button-red__active__background,
        $_button-border-active                  : $button-red__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.red {
    @include lib-link-as-button();
}

//
//  green Button Variables
//  ---------------------------------------------

$button-green__color                            : $white__color;
$button-green__background                       : $green__color;
$button-green__border                           : $green__color;

$button-green__hover__color                     : $button-green__color;
$button-green__hover__background                : $button-green__background;
$button-green__hover__border                    : $button-green__border;

$button-green__active__color                    : $button-green__hover__color;
$button-green__active__background               : $button-green__hover__background;
$button-green__active__border                   : $button-green__hover__border;

//
//  green Button
//  ---------------------------------------------

.action.green {
    @include lib-button(
        $_button-color                          : $button-green__color,
        $_button-background                     : $button-green__background,
        $_button-border                         : $button-green__border,
        $_button-color-hover                    : $button-green__hover__color,
        $_button-background-hover               : $button-green__hover__background,
        $_button-border-hover                   : $button-green__hover__border,
        $_button-color-active                   : $button-green__active__color,
        $_button-background-active              : $button-green__active__background,
        $_button-border-active                  : $button-green__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.green {
    @include lib-link-as-button();
}

//
//  orange Button Variables
//  ---------------------------------------------

$button-orange__color                            : $white__color;
$button-orange__background                       : $orange__color;
$button-orange__border                           : $orange__color;

$button-orange__hover__color                     : $button-orange__color;
$button-orange__hover__background                : $button-orange__background;
$button-orange__hover__border                    : $button-orange__border;

$button-orange__active__color                    : $button-orange__hover__color;
$button-orange__active__background               : $button-orange__hover__background;
$button-orange__active__border                   : $button-orange__hover__border;

//
//  orange Button
//  ---------------------------------------------

.action.orange {
    @include lib-button(
        $_button-color                          : $button-orange__color,
        $_button-background                     : $button-orange__background,
        $_button-border                         : $button-orange__border,
        $_button-color-hover                    : $button-orange__hover__color,
        $_button-background-hover               : $button-orange__hover__background,
        $_button-border-hover                   : $button-orange__hover__border,
        $_button-color-active                   : $button-orange__active__color,
        $_button-background-active              : $button-orange__active__background,
        $_button-border-active                  : $button-orange__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.orange {
    @include lib-link-as-button();
}

//
//  yellow Button Variables
//  ---------------------------------------------

$button-yellow__color                            : $white__color;
$button-yellow__background                       : $yellow__color;
$button-yellow__border                           : $yellow__color;

$button-yellow__hover__color                     : $button-yellow__color;
$button-yellow__hover__background                : $button-yellow__background;
$button-yellow__hover__border                    : $button-yellow__border;

$button-yellow__active__color                    : $button-yellow__hover__color;
$button-yellow__active__background               : $button-yellow__hover__background;
$button-yellow__active__border                   : $button-yellow__hover__border;

//
//  yellow Button
//  ---------------------------------------------

.action.yellow {
    @include lib-button(
        $_button-color                          : $button-yellow__color,
        $_button-background                     : $button-yellow__background,
        $_button-border                         : $button-yellow__border,
        $_button-color-hover                    : $button-yellow__hover__color,
        $_button-background-hover               : $button-yellow__hover__background,
        $_button-border-hover                   : $button-yellow__hover__border,
        $_button-color-active                   : $button-yellow__active__color,
        $_button-background-active              : $button-yellow__active__background,
        $_button-border-active                  : $button-yellow__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.yellow {
    @include lib-link-as-button();
}

//
//  pink Button Variables
//  ---------------------------------------------

$button-pink__color                            : $white__color;
$button-pink__background                       : $pink__color;
$button-pink__border                           : $pink__color;

$button-pink__hover__color                     : $button-pink__color;
$button-pink__hover__background                : $button-pink__background;
$button-pink__hover__border                    : $button-pink__border;

$button-pink__active__color                    : $button-pink__hover__color;
$button-pink__active__background               : $button-pink__hover__background;
$button-pink__active__border                   : $button-pink__hover__border;

//
//  pink Button
//  ---------------------------------------------

.action.pink {
    @include lib-button(
        $_button-color                          : $button-pink__color,
        $_button-background                     : $button-pink__background,
        $_button-border                         : $button-pink__border,
        $_button-color-hover                    : $button-pink__hover__color,
        $_button-background-hover               : $button-pink__hover__background,
        $_button-border-hover                   : $button-pink__hover__border,
        $_button-color-active                   : $button-pink__active__color,
        $_button-background-active              : $button-pink__active__background,
        $_button-border-active                  : $button-pink__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.pink {
    @include lib-link-as-button();
}

//
//  purple Button Variables
//  ---------------------------------------------

$button-purple__color                            : $white__color;
$button-purple__background                       : $purple__color;
$button-purple__border                           : $purple__color;

$button-purple__hover__color                     : $button-purple__color;
$button-purple__hover__background                : $button-purple__background;
$button-purple__hover__border                    : $button-purple__border;

$button-purple__active__color                    : $button-purple__hover__color;
$button-purple__active__background               : $button-purple__hover__background;
$button-purple__active__border                   : $button-purple__hover__border;

//
//  purple Button
//  ---------------------------------------------

.action.purple {
    @include lib-button(
        $_button-color                          : $button-purple__color,
        $_button-background                     : $button-purple__background,
        $_button-border                         : $button-purple__border,
        $_button-color-hover                    : $button-purple__hover__color,
        $_button-background-hover               : $button-purple__hover__background,
        $_button-border-hover                   : $button-purple__hover__border,
        $_button-color-active                   : $button-purple__active__color,
        $_button-background-active              : $button-purple__active__background,
        $_button-border-active                  : $button-purple__active__border
    );
    @include lib-css(font-weight, 900);
    @include lib-css(border-radius, $button__border-radius);
}

a.action.purple {
    @include lib-link-as-button();
}


//
//  Background
//  --------------------------------------------
.action.back{
    span{
        &:before{
            font-family: 'Font Awesome 5 Free';
            content: "\f053";
            display: inline-block;
            font-weight: 900;
            margin-right: 4px;
        }
    }
}
//
//  Large
//  ---------------------------------------------

.action.large {
    @include lib-font-size(16px);
    @include lib-css(padding, 14px 28px);
}

//
//  Small
//  ---------------------------------------------

.action.small {
    @include lib-font-size(12px);
    @include lib-css(padding, 8px 16px);
}

//
//  Fullwidth
//  ---------------------------------------------

.action.fullwidth {
    width: 100%;
    text-align: center;
}


//
//  Fullwidth
//  ---------------------------------------------

.action.continue{
    &:before{
        display:none !important;
    }
    &:after{
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-left: 20px;
        content: "\f054";
        display:inline-block !important;
    }
}

//
//  Other
//  ---------------------------------------------

.action.right {
    float: right;
}

.action.left {
    float: left;
}

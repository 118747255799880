.pixalicious-product-sliders{

  .slick-prev {
    left: -15px;

    &:before {
      content: "\f053";
    }
  }
  .slick-next {
    right: -15px;

    &:before {
      content: "\f054";
    }
  }

  .slick-slider{
    padding: 0 40px;
  }

  .slick-arrow{
    background: white;
    font-weight: 900;
    color: white;
    font-size: 1px;
    top: 36px;
  }

}
[data-content-type='button-item'] {
    max-width: 100%;
    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }
    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        margin: 0 10px 10px 0;
        font-weight:bold;
        box-shadow: none;
        box-sizing: border-box;
        display: inline-block;
    }

    &.pagebuilder-button-primary {
        background: #0c0;
        border: 1px solid #0c0;
        box-shadow: none;
        color: #fff;
        margin: 0 10px 10px 0;
        border-radius: 50px;
        display: inline-block;
        flex: 1 1 100%;
        font-size: 16px;
        font-weight: 600;
        padding: 13px 25px;
        text-decoration: none;
    }

    &.pagebuilder-button-secondary {
        background: #eee;
        border: 1px solid #eee;
        box-shadow: none;
        color: #2b0f1f;
        margin: 0 10px 10px 0;
        border-radius: 50px;
        display: inline-block;
        flex: 1 1 100%;
        font-size: 16px;
        font-weight: 600;
        padding: 13px 25px;
        text-decoration: none;
    }
}
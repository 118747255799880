//
//  Prices
//  ---------------------------------------------

.price-box {
    .price-including-tax {
        display: inline-block;
        white-space: nowrap;

        &:after {
            content: 'inc VAT';
            font-weight: 900;
            margin-left: $indent__xs;
        }
    }
    .price-excluding-tax {
        display: none;
    }
    .save-price {
        margin-bottom: 10px;
        font-size: 2rem;


        .price-label {
            color: $color-white;
            font-weight: 900;
        }
        .price-wrapper {
            .price {
                color: $color-white;
                font-weight: 900;
            }
        }
    }
    .price-from {
        display: block;
        margin-bottom: 0;

        .old-price {
            display: inline-block;
        }
        .price-wrapper {
            .price {
                font-weight: 900;
                font-size: 16px !important;
            }
        }
    }
    .price-to {
        display: block;
        margin: 0;

        .old-price {
            display: inline-block;
        }
        .price-wrapper {
            .price {
                font-weight: 900;
                @include lib-font-size(16px);
            }
        }
    }
    .old-price {
        clear: both;
        display: inline-block;

        .price-wrapper {
            &.price-excluding-tax {
                display: none;
            }
            &.price-including-tax {
                margin: 0;

                &:before {
                    display: none;
                }
                &:after {
                    display: none;
                }
            }
            .price {
                text-decoration: line-through;
                color:#878787;

            }
        }
    }
    .bundle-price {
        clear: both;
        display: block;
        margin-bottom: 0;

        .old-price {
            display: inline-block;
        }
        & > .price-container {
            .price {
                font-weight: 900;
                @include lib-font-size(16px);
            }
        }
    }
    &.price-final_price,
    .minimal-price,
    .normal-price {
        clear: both;
        display: block;
        margin-bottom: 0;

        .price-label {
            font-weight: 900;
            @include lib-font-size(18px);
            display: none;
        }
        .price-wrapper {
            .price {
                font-weight: 900;
                @include lib-font-size(18px );
            }
        }
    }

    .special-price {
        order: 1;
        font-size: 18px;
        line-height: 24px;

        .price-label {
            display:none;

            @include lib-font-size(18px);
            color: #253b4b;
            font-weight: 900;

            @include max-screen($screen__xs) {
                display: none;
            }
        }
        .price-wrapper {
            .price {
                font-weight: 900;
                @include lib-font-size(18px);
                color: #253b4b;
            }
        }
    }
}

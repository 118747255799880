//
//  Shop by Base
//  ____________________________________________


//
//  Common
//  --------------------------------------------

.columns .column.main {
    max-width: 100%;
}

.icon .brand-label {
    float: left;
}

.amshopby-morefrom {
    .products-grid .product-item {
        width: auto;
    }

    .product-image-container {
        min-width: auto;
    }
}

.ambrands-menu-item.parent .ambrands-link.level-top:after {
    content: '';
}


//
//  Tablet
//  --------------------------------------------
@include min-screen($screen__m) {
    .am-brands-fullwidth {
        width: 100% !important;
    }
}

//
//  Smile\Variables
//  --------------------------------------------

$autocomplete__background-color:                #ffffff;
$autocomplete__border:                          $border__color;
$autocomplete-item__border:                     $border__color;
$autocomplete-item__hover__color:               #e6e7e9;
$autocomplete-item-amount__color:               $primary__color;

//
//  Smile\ElasticsuiteCore
//  --------------------------------------------

.search-autocomplete {
    @extend .abs-add-box-sizing;
    @include lib-font-size(14px);
    position: absolute;
    z-index: 105;
    overflow: hidden;
    background: white;
    margin-top: -1px;
    border: 1px solid #ddd;

    ul{
        list-style: none;
        padding: 20px 40px;
    }
    dl {
        @include lib-list-reset-styles();

        dt {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, 1px solid $autocomplete__border);
                border-bottom: 0;
            }
            @include lib-css(border-top, 1px solid $autocomplete-item__border);
            cursor: default;
            margin: 0;
            padding: $indent__s $indent__xl $indent__s $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;
        }
        dd {
            &:not(:empty) {
                @include lib-css(background, $autocomplete__background-color);
                @include lib-css(border, 1px solid $autocomplete__border);
                border-top: 0;
            }
            @include lib-css(border-top, 1px solid $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:first-child {
                border-top: none;
            }
            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }
            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
            .clear {
                clear: both;
            }
        }
    }
}

//
//  Smile\ElasticsuiteCatalog
//  --------------------------------------------

.smile-es-range-slider {

    [data-role=from-label] {
        display: inline-block;
        padding: 0 0 $indent__m;
        font-size: 12px;

        &:before {
            content: "Price Range: ";
        }
        &:after {
            content: "  -";
        }
    }
    [data-role=to-label] {
        display: inline-block;
        padding: 0 0 $indent__m;
        font-size: 12px;

        &:before {
            content: "  ";
        }
    }
    [data-role=message-box] {
        display: block;
        @include lib-font-size(13);
        margin-bottom: $indent__xs;

        &.empty {
            @include lib-form-validation-note();
            padding: 0;
        }
    }
    .actions-toolbar {
        text-align: center;
        margin-bottom: $indent__base;
    }
    .actions-primary {
        display: inline-block;
    }
    .ui-slider {
        height: 5px;
        border-radius: 0;
        background-color: $border__color;
        clear: both;
        margin: $indent__s $indent__xs $indent__base;
    }
    .ui-slider-range {
        position: relative;
        background-color: #3dce31;
        height: 100%;
    }
    .ui-slider-handle {
        top: 3px;
        background-color: #ffffff;
        border: 1px solid #d5d5d5;
        padding: 0;
        box-shadow: 3px 2px 3px 0px #00000012;
        border-radius: 2px;
        margin: -11px 0 0 -4px;
        width: 25px;
        height: 20px;

        &:after,&:before{
            content:' ';
            display: block;
            position:absolute;
            top: 5px;

        }
        &:before{
            border-right: 4px solid #8c8c8c;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            left: 5px;
        }
        &:after{
            border-left: 4px solid #8c8c8c;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            right: 5px;
        }
    }
}

.filter {
    .filter-clear {
        @extend .action.primary;
        @extend .action.small;
        margin-bottom: $indent__s;
    }
    .filter-content .item {
        input[type="checkbox"] {
            display: none;

            &:checked {
                & + label {
                    &:before {
                        content: fa-content($fa-var-check-square);
                        color: #00cc00;
                    }
                }
            }
        }
        label {
            cursor: pointer;
            font-size: 14px;

            &:before {
                @include fa-icon();
                @include far();
                @include lib-font-size(18);
                position: relative;
                top: 1px;
                font-weight: normal;
                content: fa-content($fa-var-square);
                margin-right: $indent__xs;
                color: $border__color;
            }

        }
        a {
            &:hover {
                label {
                    &:before {
                        content: fa-content($fa-var-check-square);
                        color: $text__color;
                    }
                }
            }
        }
    }
    .block-subtitle {
        border-bottom: none;
        font-weight: $font-weight__bold;
    }
    .field.search {
        margin-bottom: $indent__m;
    }
    .actions {
        margin-bottom: $indent__base;

        div.secondary {
            display: inline-block;

            a {
                font-weight: normal;
                text-decoration: underline;
                cursor: pointer;
                color: $text__color;

                &:hover {
                    background: transparent;
                }
            }
        }
    }
    .filter-options {
        dt.filter-options-title[aria-expanded="true"]:after {
            @include lib-css(content, $icon-up);
        }
    }
    .no-results-message p {
        margin: 9px $indent__s 0;
        background-color: #ffeeee;
        padding: $indent__xs;
        color: #aa0000;
    }
}

.smile-elasticsuite-autocomplete-result {
    .product-image-box {
        float: left;
        padding: 0 $indent__xs;
        width: 55px;
        margin-right: $indent__s;
    }
    .product-shop {
        float: left;
        margin: 0 $indent__s 0 0;
        padding: 0;
        white-space: normal;
        width: 60%;
    }
    .product-item {
        .price-box {
            margin: $indent__xs 0;

            & > * {
                margin: 0;
            }
        }
    }
    .category-mini-crumb {
        font-style: italic;
        display: block;
        margin-bottom: 2px;
        font-size: $font-size__s;
        color: #929292;
    }
    .product-attribute-label {
        text-transform: uppercase;
        vertical-align: super;
        font-size: $font-size__xs;
        color: #777;
    }
    .price-box {
        span.old-price {
            display: block;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-layout-1column {
        .filter-options {
            .filter {
                &-options {
                    &-content {
                        padding: $indent__s;
                    }
                }
            }
        }
    }
}

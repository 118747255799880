//
//  Toolbar
//  ---------------------------------------------

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__l;
    background: #f6f6f6;
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
}

//
//  Amount
//  ---------------------------------------------

.toolbar-amount {
    padding: 0 15px;
    margin-right: 10px;
    font-size: 13px;
    float: left;
    line-height: 39px;
    margin-bottom: 0;
    color: #39464f;
    position: relative;

   /* &:after{
        content: " ";
        width:1px;
        height:15px;
        background:#dcdcdc;
        position: absolute;
        right:0;
        top: 14px;
        display:block;
    } */
}

//
//  Sorter
//  ---------------------------------------------

.sorter {
    float: right;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .sorter-label {
        font-size: 13px;
        float: left;
        line-height: 39px;
        margin-bottom: 0;
        color: #39464f;
        position: relative;

        &:after {
            content: ":";
        }
    }
    .sorter-options {
        width: auto;
        border-color: #ccc;
        margin: 0 5px;
        padding: 0 20px 0 10px;
        height: 36px;
        color: #39464f;
        font-size: 13px;
        background: #f6f6f6;
    }
    .sorter-action {
        color: $text__color;
        display:none !important;

        span {
            @extend .abs-visually-hidden;
        }
        &.sort-asc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-up);
            }
        }
        &.sort-desc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-down);
            }
        }
    }
}

//
//  Modes
//  ---------------------------------------------

.modes {
    float: left;
    padding: 5px 0;

    @include max-screen($screen__l) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .modes-label {
        display: inline-block;
        float: left;
        font-size: 13px;
        line-height: 28px;
        margin-bottom: 0;
        color: #39464f;
        position: relative;
        font-weight: 400;
    }
    .modes-mode {
        color: #888888;
        border: 1px solid #d9ddde;
        float: left;
        padding: 5px 6px;
        margin-left: 10px;
        font-weight: normal;
        border-radius: 3px;
        width: 70px;
        font-size: 13px;
        height: 28px;

        &.mode-grid{
            &:before {
                height: 12px;
            }
        }
        &:before {
            content:' ';
            background:url('../Magento_Catalog/images/grid.png');
            margin-right: 9px;
            position: relative;
            top: 1px;
            width: 12px;
            height: 11px;
            display: inline-block;
            background-repeat: no-repeat;
        }
        &.mode-list {
            &:before {
               background:url('../Magento_Catalog/images/list.png');
            }
        }
        &:hover,
        &.active {
            text-decoration: none;
            border-color: #939393;
        }
    }
}

//
//  Limiter
//  ---------------------------------------------

.limiter {
    display: none;
    float: right;

    @include max-screen($screen__m) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .label,
    .limiter-text {
        @include lib-font-size(15px);
    }
    .control {
        display: inline-block;

        .limiter-options {
            border-color: #c4c9cd;
            width: auto;
            margin: 0 $indent__xs;
        }
    }
}

//
//  Pages
//  ---------------------------------------------

.pages {
    display: none;
}

.breadcrumbs {
    @include lib-breadcrumbs();
    margin: $indent__m 0;

    .item {
        &:after {
            content: "/";
            margin: 0 $indent__xs;
            color: #b0b0b0;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }

        a{
            color: #878787;
            text-decoration: none;
            font-weight: 100;
            font-size: 13px;
        }
        strong{
            font-weight: 100;
            color:#39464f;
        }
    }
}

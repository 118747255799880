//
//  Shopping cart
//  ---------------------------------------------

#shopping-cart-table{
    [data-label="Exc. VAT"]:after,
    [data-label="Inc. VAT"]:after{
        font-size: 14px;
        margin-left: 0px;
    }
}

//  Summary block
.cart-summary {
@extend .abs-add-box-sizing;
@extend .abs-adjustment-incl-excl-tax;
@include lib-css(background, $sidebar__background-color);
margin-bottom: $indent__m;
padding: 1px 15px $indent__m;


#cart-totals{
    tbody{
        display: flex;
        flex-direction: column;
        align-content: space-between;
        align-items: stretch;

        tr{
            display: flex;
            justify-content: space-between;

            th{
                padding-left:0;
            }
            td{
                padding-right:0;
            }
        }

        .totals.sub{ order:0; }
        .totals.shipping.incl{
            order:1;
            border-bottom: 1px solid #ddd;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        .grand.totals.excl{ order:2;}
        .totals-tax-summary{
            order:3;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;

            .amount .price:after{
                display:none !important;
            }
        }
        .grand.totals.incl{ order:5;}
        .totals-tax-details{ display:none !important; }
    }
}

#block-shipping{
    display:none;
}
#block-summary{
    display:block !important;
}

.totals.shipping.incl{
    span{
        display:none;
    }
    &:before,
    &:after{
        display:inline-block;
    }
    &:before{
        content:'Shipping';
    }
    &:after{
        content:'Free';
    }
}
> .title {
    display: block;
    color: #3e3d30;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-top: 10px;
}

.block {
    @extend .abs-discount-block;
    margin-bottom: 0;

    .item-options {
        margin-left: 0;
    }

    .fieldset {
        margin: 15px 0 $indent__m $indent__xs;

        .field {
            margin: 0 0 $indent__s;

            &.note {
                font-size: $font-size__s;
            }
        }

        .methods {
            .field {
                > .label {
                    display: inline;
                }
            }
        }
    }

    .fieldset.estimate {
        > .legend,
        > .legend + br {
            @extend .abs-no-display;
        }
    }
}

.totals-tax-summary{
    .mark,
    .amount{
        border-bottom: 0;
        border-top:0;

        .price{
            padding-right: 0px;

            &:after{
                display:none;
            }
        }
    }
}

.cart-totals .grand .mark{
    padding: 8px 30px 8px 4px;
}
.cart-totals .grand .amount{
    padding: 8px 4px 8px 30px;
}
}

//Checkout Summary
.checkout-index-index{
.opc-block-summary{
    .data.table.table-totals{
        tbody{
            display: flex;
            flex-direction: column;
            align-items: stretch;
            align-content: space-between;

            tr{
                display: flex;
                justify-content: space-between;

                th,td{
                    border-bottom:0;
                    border-top:0;
                }
            }
            .totals.sub{
                order:1;
            }
            .totals.shipping.incl{
                order:2;
                border-bottom: 1px solid #d1d1d1;
                margin-bottom: 15px;

                .label{
                    display:none;
                }
            }
            .grand.totals.excl{
                order:3;
            }
            .totals-tax-summary{
                order:4;
                border-bottom: 1px solid #d1d1d1;
                margin-bottom: 15px;

                .price{
                    padding-right:0;

                    &:after{
                        display:none;
                    }
                }
            }
            .totals-tax-details{
                display: none !important;
            }
            .grand.totals.incl{
                order:4;
                border-bottom: 1px solid #d1d1d1;
            }

        }
    }
}
}

//  Totals block
.cart-totals {
@extend .abs-sidebar-totals;
padding-top: $indent__s;

.table-wrapper {
    margin-bottom: 0;
    overflow: inherit;
}
}

//  Products table
.cart.table-wrapper {
.items {
    thead + .item {
        border-top: $border-width__base solid $border-color__base;
    }

    > .item {
        border-bottom: $border-width__base solid $border-color__base;
        position: relative;
    }
}

.col {
    padding-top: $indent__base;

    &.qty {
        .input-text {
            @extend .abs-input-qty;
            margin-top: -5px;
        }


        .control.qty{
            border: 1px solid #ddd;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;

            & > *{
                height: 43px;
                line-height: 6px;
                margin: 0 0 0 0 !important;
                display: block;
                border: 0;
                padding: 15px;
            }
        }
        .label {
            @extend .abs-visually-hidden;
        }
    }
}

.item {
    &-actions td {
        padding-bottom: $indent__s;
        text-align: center;
        white-space: normal;
        padding-left: 0;
        padding-right: 0;
    }

    .col {
        &.item {
            display: block;
            min-height: 75px;
            padding: $indent__m 0 $indent__s 75px;
            position: relative;
        }
    }
}

.actions-toolbar {
    @extend .abs-add-clearfix;

    > .action {
        margin-bottom: $indent__s;
        margin-right: $indent__s;

        &.action-delete {
            @extend .action.white;
        }
        &.action-towishlist {
            @extend .action.secondary;
        }
        &.action-edit {
            @extend .action.primary;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.action {
    &.help.map {
        @extend .abs-action-button-as-link;
        font-weight: $font-weight__regular;
    }
}

.product {
    &-item-photo {
        display: block;
        left: 0;
        max-width: 60px;
        padding: 0;
        position: absolute;
        top: 15px;
    }

    &-item-details {
        white-space: normal;
    }

    &-item-name {
        display: inline-block;
        font-weight: $font-weight__regular;
        margin-top: -6px;
    }
}

.gift-registry-name-label {
    &:after {
        content: ':';
    }
}

//  Product options
.item-options {
    @extend .abs-product-options-list;
    @extend .abs-add-clearfix;
    font-size: $font-size__s;
    margin-bottom: $indent__s;
}

.product-item-name + .item-options {
    margin-top: $indent__s;
}

.product-image-wrapper {
    @extend .abs-reset-image-wrapper;
}

.action.configure {
    display: inline-block;
    margin: 0 0 $indent__base;
}
}

.cart-container {
.form-cart {
    @extend .abs-shopping-cart-items;

    .actions {
        .action.continue {
            @extend .action.secondary;
        }
        .action.clear {
            @extend .action.default;
        }
        .action.update {
            @extend .action.primary;
        }
    }
}

.checkout-methods-items {
    @extend .abs-reset-list;
    margin-top: $indent__base;
    text-align: center;

    .action.primary {
        @extend .abs-button-l;
        width: 100%;
    }

    .item {
        & + .item {
            margin-top: $indent__base;
        }
    }
}
}

//  Products pager
.cart-products-toolbar {
.toolbar-amount {
    margin: $indent__m 0 15px;
    padding: 0;
    text-align: center;
}

.pages {
    margin: 0 0 $indent__m;
    text-align: center;

    .items {
        > .item {
            border-bottom: 0;
        }
    }
}
}

.cart-products-toolbar-top {
border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
+ .cart-tax-total {
    display: block;
}
}

.cart.table-wrapper,
.order-items.table-wrapper {
.col.price,
.col.qty,
.col.subtotal,
.col.msrp {
    text-align: right;
}
.price-excluding-tax,
.price-including-tax{
    white-space: nowrap;
}
}

//
//  Mobile
//  _____________________________________________

@include max-screen(1049px) {
    .cart {
    &.table-wrapper {
        overflow: inherit;

        thead {
            .col {
                &:not(.item) {
                    display: none;
                }
            }
        }

        .col {
            &.qty,
            &.price,
            &.subtotal,
            &.msrp {
                box-sizing: border-box;
                display: block;
                float: left;
                text-align: center;
                white-space: nowrap;
                width: 33%;

                &:before {
                    content: attr(data-th) ':';
                    display: block;
                    font-weight: $font-weight__bold;
                    padding-bottom: $indent__s;
                }
            }

            &.msrp {
                white-space: normal;
            }
        }

        .item {
            .col.item {
                padding-bottom: 0;
            }
        }
    }
}

    .cart-container {
    .form-cart {
       // @extend .abs-shopping-cart-items-mobile;
    }
}

    .cart.table-wrapper .col.qty .control.qty>*{
        padding: 9px;
        width: 34px;
    }

    #shopping-cart-table{
        th{
            display:none !important;
        }
        .col.item {
            float: left;
            width: 100%;
            display: flex !important;
            align-content: center;
            align-items: center;
        }
        .product-item-details{
            .product-item-name{
                display: flex;
                align-content: center;
                align-items: center;
            }
        }
        tr.item-info{
            display: block;
        }
        .col.item{
            width: 100%;
            display: inline-block;
            float: left;
        }
        .col.price,
        .col.qty,
        .col.subtotal{
            width: 33.333%;
            display: inline-block;
            float: left;
        }
    }
}
@include max-screen(600px) {
.cart.table-wrapper .col.msrp,
.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal {
    display: inline-block;
    float: left;
    text-align: center;
    width: 33.33% !important;
    padding: 0;
}
.cart.table-wrapper{
    .cart-price{
        display: block;
    }
}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
.cart-container {
    @extend .abs-add-clearfix-desktop;
    .form-cart {
        @extend .abs-shopping-cart-items-desktop;
    }

    .widget {
        float: left;
    }
}

.cart-summary {
    float: right;
    position: relative;
    width: 23%;

    .actions-toolbar {
        .column.main & {
            @extend .abs-reset-left-margin-desktop;
            > .secondary {
                float: none;
            }
        }
    }

    .block {
        .fieldset {
            .field {
                @include lib-form-field-type-revert($_type: block);
                margin: 0 0 $indent__s;
            }
        }
    }
}

.cart {
    &.table-wrapper {
        .items { // Google Chrome version 44.0.2403.107 m fix
            min-width: 100%;
            width: auto;
        }

        .item {
            .col {
                &.item {
                    padding: 27px 8px $indent__s;
                }
            }

            &-actions {
                td {
                    text-align: right;
                }
            }
        }

        .product {
            &-item-photo {
                display: table-cell;
                max-width: 100%;
                padding-right: $indent__base;
                position: static;
                vertical-align: top;
            }

            &-item-details {
                display: table-cell;
                vertical-align: top;
                white-space: normal;
            }
        }

        .item-actions {
            td {
                text-align: left;
            }
            .actions-toolbar {
                @extend .abs-reset-left-margin-desktop;
                text-align: left;

                &.linked {
                    .action-edit {
                        display: none;
                    }
                }
            }
        }
    }

    .action {
        margin-bottom: $indent__s;
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        line-height: 30px;
        margin: 0;
    }

    .pages {
        float: right;
        margin: 0 0 1px;

        .item {
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.cart.table-wrapper {
    .cart-products-toolbar {
        + .cart {
            thead {
                tr {
                    th.col {
                        padding-bottom: 7px;
                        padding-top: 8px;
                    }
                }
            }
        }
    }

    .cart {
        + .cart-products-toolbar {
            margin-top: $indent__m;
        }
    }
}
}

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1195px;

    [data-element='inner'] {
        box-sizing: border-box;
    }
}

@include max-screen($screen__m) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important;
        }
    }
}
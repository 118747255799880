//Titles
.catalog-category-view{
    .page-title{
        font-size: 40px;
        margin-bottom: 40px;

        @include max-screen($screen__m) {
            font-size:30px;
        }
    }
    .category-view{
        display: flex;
        width: 100%;

        .category-text-wrap{
            flex: 1 1 100%;
        }

        .category-image-wrap{
            text-align: right;
            min-width: 200px;
            flex: 1 1 100%;
        }
    }
}



//
//  Category Top
//  ---------------------------------------------
.category-top {
    .page-title-wrapper {
        border-top: 1px solid $gray-lightest__color;

        .page-title {
            @include lib-font-size(33px);
            font-weight: 600;
            text-align: center;
            margin: $indent__l 0;
        }
    }
    .category-description {
        text-align: center;
        margin-bottom: $indent__base;

        h2 {
            @include lib-font-size(18px);
            margin: $indent__base 0 $indent__s;
        }
        p {
            @include lib-font-size(15px);
            color: $gray__color;
        }
    }
}

//
//  Category Landing Top
//  ---------------------------------------------

.category-landing-top {
    background-color: #e6ecdb;
    padding: $indent__base 0;
    margin-bottom: $indent__base;

    @include max-screen($screen__s) {
        padding: $indent__l 0;
        text-align: center;
    }

    .content {
        align-self: center;

        @include max-screen($screen__s) {
            width: 100%;
        }

        .page-title {
            @include lib-font-size(36px);
            font-weight: 600;
            margin: 0 0 $indent__base;

            @include max-screen($screen__s) {
                margin-bottom: $indent__s;
            }
        }
        .category-description {
            @include lib-font-size(15px);
            color: $gray__color;

            p {
                @include lib-font-size(15px);
                color: $gray__color;
            }
        }
    }
    .image {
        @include max-screen($screen__s) {
            display: none;
        }
    }
}

//
//  Category Subcategory list
//  ---------------------------------------------

.category-subcategory-list {
    .heading {
        @include lib-font-size(15px);
        font-weight: 900;
        margin-bottom: $indent__base;
    }
    ul {
        list-style: none;
        padding: 0;
        margin-bottom: $indent__base;

        li {
            margin-bottom: $indent__base;

            a {
                display: block;
                @include lib-font-size(15px);
                color: $text__color;
            }
        }
    }
}

//
//  CategorySubcategories
//  ---------------------------------------------

.category-subcategories {
    margin-bottom: $indent__xl;

    @include max-screen($screen__xs) {
        .row {
            margin-left: 0;
        }
        .col {
            padding-left: 0;
        }
    }
    .category {
        display: block;
        text-align: center;
        margin-bottom: $indent__base;

        .image {
            display: block;
            position: relative;
            padding-bottom: 200px;

            img {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .title {
            display: block;
            color: $text__color;
            @include lib-font-size(19px);
            font-weight: 600;
            margin: $indent__s 0;
        }
        .link {
            display: block;
            font-weight: 900;
        }
        &:hover {
            text-decoration: none;

            .link {
                color: $secondary__color;
            }
        }
    }
}



@include max-screen($screen__s) {
    .category-landing-page{
        .sidebar.sidebar-main{
            display: none;
        }
    }
}
//
//  End
//  ---------------------------------------------
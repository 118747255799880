//
//  Common
//  --------------------------------------------
.amscroll-page-num {
        clear: both;
        padding: 6px 10px;
        color: $amscroll__text__color;
        letter-spacing: .03em;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 2rem;

    &.-amscroll-button {
        display: inline-block;
        padding: 5px 14px;
        border: 1px solid $amscroll__border__color;
        border-radius: 2px;
        background: $amscroll__background-color;
    }

    &.-amscroll-divider {
        border-bottom: 1px solid $amscroll__border__color;
    }

    &.-amscroll-background {
        background: $amscroll__background-color;
    }
}
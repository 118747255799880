//Main Homepage Banner
#main-banner {
    margin-bottom: 30px;
    position: relative;

    .main-slider-arrows {
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 1;

        .container {
            position: relative;

            .slick-arrow {
                width: 43px;
                height: 47px;
                background: #00000036;
                font-size: 16px;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                padding: 0;

                &.slick-prev:before {
                    content: "\f053";
                }

                &.slick-next:before {
                    content: "\f054";
                }
            }
        }
    }

    .slick-slide {
        text-align: center;
        position: relative;
        z-index: -1;
        display: flex;
        justify-content: center;

        img {
            left: -50%;
            margin-left: 50%;
            position: relative;
            max-width: 100%;
            object-fit: contain;
        }
    }

    .slick-active {
        z-index: 99 !important;
        background: white;
    }

    @include max-screen($screen__l) {
        height: 239px;

        .slick-slide {
            z-index: -1;

            img {
                left: -500px;
                max-width: 1000px;
                height: 239px !important;
            }
        }
    }

    @include max-screen($screen__m) {
        height: 183px;

        .slick-slide {
            z-index: -1;

            img {
                left: -383px;
                max-width: 766px;
                height: 183px !important;
            }
        }
    }
    @include max-screen($screen__s) {
        height: 153px;

        .slick-slide {
            z-index: -1;

            img {
                left: -340px;
                max-width: 640px;
                height: 153px !important;
            }
        }
    }
}

//HOMEPAGE TWO SALES BANNERS
.homepage-two-sales-banner {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    flex-wrap: nowrap;
    gap: 10px;
    margin-bottom: 50px;

    @media only screen and (max-width: 950px) {
        flex-direction: column;
    }


    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        align-content: flex-start;
        flex-wrap: nowrap;
        justify-content: flex-end;

        @media only screen and (max-width: $screen__m) {
            width: 100%;
        }

        a {
            display: flex;
            width: 100%;
            justify-content: space-around;
            gap: 10px;
            border: 1px solid #b0b5b9;
            align-items: center;
            white-space: nowrap;
            padding: 10px 20px;
            height: 160px;

            &:hover {
                text-decoration: none;
            }

            .title {
                font-size: 24px;
                font-weight: bold;
                line-height: 28px;
            }

            .tag {
                font-size: 24px;
                color: #ec1667;
                display: block;

                sub {
                    font-size: 11px;
                    font-weight: 300;
                }
            }

            &.action.primary {
                margin-top: 20px;
            }
        }
    }
}

//USP bar
#usp-bar {
    border-top: 1px solid #dcdad3;
    border-bottom: 1px solid #dcdad3;
    padding: 15px 0;

    @include min-screen($screen__s) {
        border-bottom: 1px solid $primary__color;
    }
    @include min-screen($screen__l) {
        padding: 15px 50px;
    }

    a {
        padding-left: 55px;
        position: relative;
        display: block;
        font-size: 19px;
        color: $green-dark__color;
        font-weight: bold;
        line-height: 19px;

        &:hover {
            text-decoration: none;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
        }

        span {
            color: $secondary__color;
            font-size: 13px;
        }
    }
}

//Quick Tabs
#quick-tabs {
    text-align: center;
    margin-bottom: 30px;

    @include lib-clearfix();

    h3 {
        font-size: 30px;
        color: $primary__color;
    }

    p {
        color: #6b6b6b;
    }

    .action.primary {
        margin: 20px auto;
        display: block;
        width: 160px;
        text-align: center;
    }
}

//Brand Images
.homepage-brand-images {
    margin-bottom: 60px;

    a {
        flex: 1 1 auto;
        display: flex;
        margin-bottom: 0;
        position: relative;

        &:before {
            content: 'Shop now';
            display: block;
            border-radius: 40px;
            position: absolute;
            bottom: 20px;
            right: 20px;
            z-index: 1;
            padding: 12px 30px;
            background: $secondary__color;
            color: white;
        }

        img {
            width: 100%;
        }
    }
}


//Homepage latest
#homepage-latest {
    margin-bottom: 50px;

    & > h3 {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 13px;
        margin-bottom: 30px;
        font-size: 24px;
        color: #39464f;
        font-weight: 400;
    }
}


//Popular Images
.shop-popular-products {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 70px;

    .title {
        width: 100%;
        text-align: center;
        font-size: 24px;
        color: #39464f;
        font-weight: 400;
        margin-bottom: 30px;
    }

    a {
        flex: 1 1 auto;
        display: flex;
        margin-bottom: 0;
        position: relative;
        background: #f5f4f4;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        font-size: 16px;

        img {
            width: 100%;
            margin-bottom: 10px;
            max-width: 170px;
        }
    }
}

//Intro text
#into-text {
    text-align: center;
    max-width: 730px;
    margin: 0 auto 50px;

    h1 {
        font-size: 24px;
    }
}


.brands-slider {
    margin-bottom: 30px;
    border-top: 1px solid #ebebeb;
    padding-top: 20px;

    .slick-track {
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    & > .slick-slider {
        padding: 0 40px;

        .slick-slide {
            text-align: center;
            display: flex;
            justify-content: center;

            img {
                display: inline-block;
                text-align: center;
            }
        }

        .slick-arrow {
            width: 36px;
            height: 36px;
            border: 1px solid #676767;
            color: #676767;
            -webkit-border-radius: 55px;
            -moz-border-radius: 55px;
            border-radius: 55px;

            .slick-slide {
                text-align: center;
            }

            &:before,
            &:after {
                position: relative;
                width: 7px;
                height: 1px;
                background: #676767;
                content: ' ';
                display: block;
                left: 12px;
            }

            &.slick-prev {
                &:before {
                    -ms-transform: skewY(-45deg);
                    transform: skewY(-45deg);
                    top: 4px;
                }

                &:after {
                    -ms-transform: skewY(45deg);
                    transform: skewY(45deg);
                    top: -6px;
                }
            }

            &.slick-next {
                &:before {
                    -ms-transform: skewY(45deg);
                    transform: skewY(45deg);
                    top: 4px;
                }

                &:after {
                    -ms-transform: skewY(-45deg);
                    transform: skewY(-45deg);
                    top: -6px;
                }
            }
        }
    }
}

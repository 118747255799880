.pagebuilder-column {
    box-sizing: border-box;
}

@include max-screen($screen__m) {
    .pagebuilder-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
        max-width: 100%;
    }
}
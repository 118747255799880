#mobile-menu{
  background-color:$primary__color;

  @include max-screen($screen__m) {
    display: flex;
    flex-direction: column;
  }

  .switcher{
    &.switcher-currency,
    &.switcher-language{
      display: block !important;
      text-align: left;
      background: #f2f2f2;

      #switcher-currency-trigger-nav strong:before{
        font-weight: 500;
        font-size: 13px;
      }
      #switcher-language-trigger-nav strong span{
        display: inline-block;
      }


      ul.dropdown{
        background: #e5e5e5;
        width: 200px;

        li{
          a{
            background: #e5e5e5;
            padding: 10px 20px;
            text-align: left;
            font-size: 13px;
            color: $gray-darker__color;
            display: block;
            width: 100%;
            border-bottom: 1px solid #d8d8d8;
          }
        }
      }
    }
  }

  .mobile-menu-item{
    clear: both;

    .navigation{
      background-color:$primary__color;

      .level0.active > a:not(.ui-state-active),
      .level0.has-active > a:not(.ui-state-active) {
        border-color: #3dce31;
        border-style: solid;
        border-width: 0 0 0 5px;
      }

      .ui-menu-icon.ui-icon.ui-icon-carat-1-e {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
        z-index: 9;
      }
    }
    .title{
      background-color: #1b2f3d !important;
      color: #ffffff !important;
      padding: 10px;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
      -webkit-box-shadow: 0px 2px 15px -5px #000000;
      box-shadow: 0px 2px 15px -5px #000000;
    }

    .navigation .level0 > .level-top,
    .navigation .level0 a,
    nav > a{
      background-color: #ffffff;
      padding: 10px 20px;
      text-align: left;
      font-size: 13px;
      color: #253b4b;
      display: inline-block;
    }
    .navigation .level0 a,
    .navigation .level0 > .level-top,
    nav > a{
      text-transform: initial;
      position: initial;
    }


    .navigation .level0.active > .level-top{
      .ui-menu-icon.ui-icon.ui-icon-carat-1-e:before{
        display: none;
      }
    }


    .navigation .level0 > .level-top{
      .ui-menu-icon.ui-icon.ui-icon-carat-1-e {
        &:before,
        &:after {
          background: #aaaaaa;
          content: ' ';
          display: block;
          position: absolute;
          top: 17px;
          right: 10px;
        }
        &:after{
          width: 12px;
          height: 1px;
          top: 18px;
        }
        &:before{
          width: 1px;
          height: 12px;
          right: 15px;
          top: 13px;
        }
      }
    }


    .navigation{
      ul{
        padding:0px;

        .level0{
          border-top: 1px solid #ffffff;
          text-align: left;
          background: white;
          border-bottom: 1px solid #dedede;
          position: relative;

          &  > .level-top:before{
            width: 2px;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
    .navigation .level0 > .level-top{
      &.ui-state-active:before{
        display: none;
      }
      &:after{ height: 2px; }
    }
    .navigation > ul.navigation{
      background: white;
      text-align: left;
    }
  }
}

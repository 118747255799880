//
//  Magento\CatalogSearch
//  --------------------------------------------

//Toggle dropdown
#search-button{
    display: block;
    width: 24px;
    height: 29px;
    padding: 0;
    background-image: url(../../web/images/search.png);
    background-repeat: no-repeat;
    margin-right: 30px;
    float: left;
    background-position: center;
    transition: width 3s, height 3s;
    cursor: pointer;

    &:before,
    &:after{
        content:'';
        width:0;
        height:0;
    }
    span{
        display: none;
    }

    &.close-icon{
        background-image: none;
        position: relative;

        &:before,
        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #252b63;
            border-radius: 2px;
            top: 14px;
            box-shadow: 0 0 2px 0 #ccc;
            transition-duration: 0.8s;
            transition-property: transform;
        }

        &:before{
            -webkit-transform:rotate(45deg);
            -moz-transform:rotate(45deg);
            transform:rotate(45deg);
            left:0px;
        }
        &:after{
            -webkit-transform:rotate(-45deg);
            -moz-transform:rotate(-45deg);
            transform:rotate(-45deg);
            right:0px;
        }
    }
}

.block-search {
    flex: 1 1 auto;
    display: flex;
    margin-bottom:0;

    @include max-screen($screen__l) {
        order: 99;
        width: 100%;

        .block-content{
            padding: 0 !important;
        }
    }

    .actions{
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    }

    @include max-screen($screen__l) {
        order: 99;
        width: 100%;

        .block-content{
            padding: 0 !important;
        }
    }

    .field.search{
        &:before {
            content: ' ';
            display: block;
            width: 15px;
            height: 15px;
            border: 1px solid #898989;
            -webkit-border-radius: 44px;
            -moz-border-radius: 44px;
            border-radius: 44px;
            position: absolute;
            right: 17px;
            top: 12px;
        }
        &:after {
            width: 8px;
            height: 1px;
            content: ' ';
            display: block;
            transform: rotate(45deg);
            background: #898989;
            -ms-transform: rotate(45deg);
            position: absolute;
            right: 12px;
            top: 26px;
        }
    }

    .block {
        &-title {
            display: none;
        }
        &-content {
            width: 100%;
            margin: 0;
            padding: 0 30px
        }
    }
}

.minisearch {
    position: relative;

    .label {
        display: none;
    }
    .control {
        overflow: hidden;
    }
    #search {
        @include lib-input-placeholder($gray__color);
        @include lib-css(border-radius, 4px);
        background-color: #fcfcfc;
        border-color: #d8d8d8;
        height: 45px;
        width: 100%;
        box-shadow: none;
        margin: 0;
        padding-left: 41px;
        border-radius: 4px;
        z-index: 3;
    }
    
    .action.search {
        @include lib-button-reset();
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-shadow: none;
        font-weight: 400;
        position: absolute;
        left: 0;
        top: 0;
        height: 45px;
        width: 40px;
        z-index: 5;
        border-left: 0;
        
        span {
            @extend .abs-visually-hidden;
        }
        
        &:disabled {
            opacity: 1;
            cursor: default;
        }
        
        &:before {
            width: 24px;
            margin: 0 auto;
            height: 24px;
            content: ' ';
            display: block;
          }
         
    }
}

//
//  Product page
//  _____________________________________________


//
//  Product page layout
//  ---------------------------------------------

.row {
  &.primary {
    margin-bottom: $indent__xxl;

    @include max-screen($screen__xl) {
      margin-bottom: $indent__xl;
    }
    @include max-screen($screen__l) {
      margin-bottom: $indent__l;
      padding: 0 0;
    }
    @include max-screen($screen__m) {
      margin-bottom: $indent__base;
    }

    .product.media {
      @include make-xxs-column(12);
      @include make-m-column(6);
    }
    .product-info-main {
      @include make-xxs-column(12);
      @include make-m-column(6);
    }
  }
}


//
//  Product media
//  ---------------------------------------------

.product-item-details {
  .product-image-container {
    display: block;
    max-width: 100%;
    width: auto !important;
  }
  .product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@include max-screen(700px) {
  #reviews-widget {
    width: 100px;
  }
  .product-info-main .action.towishlist{
    border: 1px solid #ddd;
    padding: 0 15px;
    border-radius: 6px;
    margin: 8px 0 10px;
    display: inline-block;
  }
}

//
//  Product media
//  ---------------------------------------------

.product.media {
  position: relative;

  .gallery-placeholder{
    margin-bottom: 50px;
  }

  //Thumbnails wrapper
  .product.photo .photo.image {
    @extend .abs-adaptive-images-centered;
  }
  .placeholder .photo.container {
    max-width: 100%;
  }
  .notice {
    @include lib-css(color, $text__color__muted);
    @include lib-font-size($font-size__s);
    margin: $indent__s 0;
  }
  .product.thumbs {
    margin: $indent__base 0 $indent__l;
  }
  .items.thumbs {
    @include lib-list-inline();

    .active {
      display: block;
      line-height: 1;
    }
  }

  .model-notice{
    text-align:center;
    padding: 0 80px;
    font-size:16px;
  }

  //arrows
  .slick-prev.slick-arrow,
  .slick-next.slick-arrow{
    background: #fff;
    font-size: 1px;
    font-weight: 900;
    color: #fff;
    top: 38px;
  }
}

//
//  Product clean
//  ---------------------------------------------

.product-info-clean {
  h2 {
    text-align: center;
    @include lib-font-size(28);
    margin: $indent__xl 0;

    @include max-screen($screen__xl) {
      margin: $indent__l 0;
    }
    @include max-screen($screen__l) {
      margin: $indent__base 0;
    }

    strong {
      color: $secondary__color;
    }
  }
  .more {
    text-align: center;

    .fas {
      margin-left: $indent__xs;
    }
  }
  .col-l-2 {
    @include min-screen($screen__l) {
      width: 20%;
    }
  }
  .row {
    @include max-screen($screen__xs) {
      margin-right: 0;
    }
  }
  .col {
    @include max-screen($screen__xs) {
      padding-right: 0;
    }
  }
  .image {
    text-align: center;
    line-height: 1.1;
    @include lib-font-size(16);
    margin-bottom: $indent__xl;

    @include max-screen($screen__xl) {
      margin-bottom: $indent__l;
    }
    @include max-screen($screen__l) {
      margin-bottom: $indent__base;
    }
    @include max-screen($screen__xs) {
      @include lib-font-size(14);
    }

    img {
      display: inline-block;
      margin-bottom: $indent__s;
    }
    span {
      height: 34px;
      display: block;
    }
  }
}

//
//  Product main
//  ---------------------------------------------
@include min-screen(700px) {
  .sticky-product-main{
  &.sticky {
    z-index: 9999;
    float: left;
    background: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .product.short-description,
    .product.attribute.overview,
    .action.towishlist,
    .product-social-links,
    .sku,
    .manufacturercode,
    .product-category-uspss,
    #addition-product-tab,
    .trust-review{
      display: none !important;
    }

    .page-title-wrapper.product{
      flex-grow: 1;
    }

    h1.page-title{
      font-size: 18px;
      line-height: 21px;
      padding-right: 20px;

      @include max-screen($screen__m) {
        font-size: 16px;
      }
    }

      #price-block{
        border-top: 0;
        border-bottom: 0;
        padding: 0;
        white-space: nowrap;

        .price-final_price .price-wrapper .price,
        .normal-price .price-wrapper .price{
          font-size: 2rem;

          [data-label="(Exc VAT)"]::after,
          [data-label="(Inc VAT)"]::after {
            @include max-screen($screen__m) {
              font-size: 12px;
            }
          }
        }
      }
      .product-add-form{
        border-top: 0;
        border-bottom: 0;
        padding: 0;

        .action.tocart{
          @include max-screen($screen__m) {
            width: 38px;
            font-size: 16px;
            padding: 10px 15px;
            background-image: url(../images/basket-white.png);
            background-repeat: no-repeat;
            background-position: 9px 6px;

            span{
              display:none
            }
          }


        }
      }
      .product-info-main{
        max-width: 1400px;
        margin: 0 auto;
        width: 100%;
        height: 74px;
        float: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include max-screen($screen__m) {
          height: 74px;
        }
        &:before,
        &:after{
          display: none;
        }
      }
      .input-text.qty{
        @include max-screen($screen__m) {
          width: 34px;
        }
      }
    }
  }
}

.product-info-main {
  //Alert
  &.alert {
    margin: $indent__s 0;
  }

  //Poduct Title
  .page-title-wrapper {
    .page-title {
      @include lib-font-size(28px);
      font-weight: 400;
      margin: 0 0 $indent__s;
      line-height: 32px;

      @include max-screen($screen__m) {
        margin-top: $indent__base;
      }
    }
  }

  .product.attribute{
    gap: 10px;
    display: flex;

    strong{
      font-weight:400;

      &:after {
        content: ':';
      }
    }
    div{
      font-weight:200;
    }
  }

  //Trust review
  .trust-review{
    display: flex;
    align-items: center;
  }

  //Product Price
  #price-block{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e5e4e1;
    border-bottom: 1px solid #e5e4e1;
    padding: 15px 0;
    margin-bottom: 15px;
    margin-top: 15px;

    .product-info-price{
      .price-box{
        display: flex;
        flex-direction: column;

        .special-price{
          order: 1;
        }
        .old-price{
          order:2;
        }
      }
    }
  }

  .product.attribute.short-description {
    margin-bottom: $indent__l;
    font-family:'Roboto', sans-serif;

    .type {
      display: none;
    }
    .value {
      @include lib-font-size(15px);
    }
  }

  //Price
  .price-box {

    .save-price {
      &:before {
        content: '/';
      }
    }
    //Old Price
    .old-price {

      .price-wrapper {
        .price {
          font-size: 2rem !important;
          color: $green-dark__color;
        }
      }
    }

    //Normal Price
    &.price-final_price,
    .normal-price {
      .price-wrapper {
        .price {
          @include lib-font-size(30px);
          color: $primary__color;
        }
      }
    }

    //Special Price
    .special-price {
      .price-wrapper {
        .price {
          font-size: 3.2rem;
          color: $special__color;
        }
      }
    }
  }

  //Sku / stock price
  .product-info-stock-sku{
    padding-top: 20px;
    border-top: 1px solid #ececec;
    margin: 20px 0;

    .stock{
      &.unavailable{
        i{
          color: #b54b4b;
        }
      }
      &.available{
        i{
          color:#80b54b;
        }
      }
    }
    span{
      font-size:15px;
      color: #6d6d6d;
    }
  }

  //Add to Form
  .product-add-form {
    @include lib-clearfix();
    position: relative;
    border-top: 1px solid #e5e4e1;
    border-bottom: 1px solid #e5e4e1;
    padding: 20px 0;
    margin-bottom: 15px;
    margin-top: 15px;

    .product-options-wrapper {
      margin-bottom: 0;

      .fieldset {
        & > .field:not(.configurable):not(.choice) {
          margin-bottom: $indent__s;

          & > .label {
            width: auto;
            padding: 4px 0;
          }
          & > #value {
            display: none;
          }
          & > .control {
            width: auto;
            float: right;

            .swatch-attribute-options {
              margin: 0 -5px 0 0;
            }
            .choice {
              @include lib-clearfix();

              input {
                display: none;

                &[type="checkbox"] {
                  float: left;
                  display: block;
                  visibility: hidden;
                }
                &:checked {
                  & + .label {
                    &:before {
                      content: fa-content($fa-var-check-square);
                      color: $primary__color;
                    }
                    &.loading {
                      &:before {
                        @extend .fa-spin;
                        font-weight: 900;
                        content: fa-content($fa-var-spinner);
                      }
                    }
                  }
                }
              }
              .label {
                float: right;
                cursor: pointer;

                &:before {
                  @include fa-icon();
                  @include far();
                  content: fa-content($fa-var-square);
                  color: #c4c9cd;
                }
                &.loading {
                  &:before {
                    @extend .fa-spin;
                    font-weight: 900;
                    content: fa-content($fa-var-spinner);
                  }
                }
              }
            }
          }
          &:last-child {
            margin-bottom: $indent__m;
          }
        }
      }
    }

    .box-tocart {
      .fieldset {
        display: flex;
        gap: 20px;
        justify-content: space-between;

        & > .field {
          text-align: left;
          display: flex;
          align-items: center;
          margin: 0;
        }
      }
      .field.qty {
        .label {
          position: absolute;
          left: $indent__m;
          top: 0;
          line-height: 44px;
          padding: 0;
          width: 75px;
          @include lib-font-size(15px);
          font-weight: normal;
        }
        .control {
          position: relative;
          display: flex;
          width: auto;
          gap: 4px;
        }
        .qty {
          display: block;
          text-align: center;
          height: 38px;
          width: 51px;
          line-height: 38px;
          font-size: 1.5rem;
          border-radius: 3px;
        }
        .btn-qty-decrease,
        .btn-qty-increase {
          background-color: #ffffff;
          transition: all 0.1s;
          line-height: 40px;
          height: 30px;
          width: 30px;
          text-align: center;
          color: #cecece;
          padding: 0;
          position: relative;
          border: 1px solid #d5d5d5;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          margin-top: 5px;

          &:hover {
            background-color: #dfdfdf;
            color: $color-white;
          }

          &:before{
            width: 10px;
            height: 2px;
            left: 9px;
            top: 13px;
          }

          &:after,
          &:before{
            content:' ';
            display: block;
            background-color:#6f6f6f;
            position: absolute;
          }
        }

        .btn-qty-increase{
          &:after{
            height: 10px;
            width: 2px;
            left: 13px;
            top: 9px;
          }
        }
      }

      .paypal.checkout {
        @include make-xxs-column(12);
        text-align: center;
      }

      .actions {
        position: relative;
        display: flex;
        flex-grow: 1;
        gap: 20px;

        .label {
          display: none;
        }
        .action.tocart {
          font-size: 16px;
          flex: 1 1 100%;
          border-radius: 50px;
          font-weight: 600;
        }

        //Wish List
        .action.towishlist {
          background: #eeeeee;
          border-radius: 50px;
          width: 52px;
          height: 46px;
          text-align: center;
          padding: 0;

          &:before{
            margin-right: 0;
          }
          span {
            color: #6d6d6d;
            font-size: 14px;
            display:none;
          }
        }
      }
      .product-social-links {
        @include make-xxs-column(2);
        text-align: center;

      }
      .prices-tier {
        @include make-xxs-column(12);
        margin-bottom: $indent__base;

        & > li {
          padding: $indent__xs $indent__s;

          &:nth-of-type(2n+1) {
            background-color: #e7e8ea;
          }
          .price-container {
            display: none;
          }
          strong {
            float: right;
            color: #f13131;
            font-weight: normal;
          }
        }
      }
    }
  }

  .product-category-uspss{
    padding-left: 15px;
    flex-grow: 1;
    list-style: disc;
    margin-bottom: 15px;

    li{
      color:#00cc00;
      margin-bottom: 5px;
      font-size: 16px;

      span{
        color: $primary__color;
        font-size: 16px;
      }
    }
  }

  #addition-product-tab{

    .tab {
      padding: 10px;
      border-bottom: 1px solid #e5e4e1;
      background:#fafafa;
      cursor:pointer;
      position: relative;

      & > div:before,
      & > div:after{
        content: ' ';
        display: block;
        position: absolute;
        width: 11px;
        height: 3px;
        border-radius: 3px;
        top: 10px;
        background: #39464f;
      }

      & > div:before{
        transform: rotate(-45deg);
        right: 23px;
        top: 24px;
      }

      & > div:after{
        transform: rotate(45deg);
        right: 29px;
        top: 24px;
      }

      &.active{
        border-bottom: 0;

        & > div:before{
          transform: rotate(45deg);
        }

        & > div:after{
          transform: rotate(-45deg);
        }
      }

      .product.attribute{
        gap: 10px;
        display: flex;
        align-items: center;

        &:before{
          content:' ';
          display: inline-block;
          width:54px;
          height: 31px;
          background-position:center;
          background-repeat: no-repeat;
        }
        &.condition{ &:before{ background-image:url('../Magento_Catalog/images/condition-icon.png');}}
        &.delivery{ &:before{ background-image:url('../Magento_Catalog/images/delivery-icon.png');}}
        &.returns{ &:before{ background-image:url('../Magento_Catalog/images/return-icon.png');}}
        &.warranty{ &:before{ background-image:url('../Magento_Catalog/images/warranty-icon.png');}}

        strong{
          font-weight:700;
        }
      }
    }

    [data-role="content"]{
      border-bottom: 1px solid #e5e4e1;
      background:#fafafa;
      padding: 10px 30px;
    }
  }
}


//
//  Product reviews
//  ---------------------------------------------

.product-info-reviews {
  @include max-screen($screen__l) {
    padding-left: $indent__xxl;
  }
  @include max-screen($screen__m) {
    padding-left: 0;
    text-align: center;

  }

  h2 {
    @include lib-font-size(20);
    letter-spacing: 0.04em;
    color: $gray__color;
    margin-top: $indent__m;
    font-weight: 400;
  }
}

//
//  Product related / upsell
//  ---------------------------------------------
#pixalicious-related-slider,
#pixalicious-upsell-slider{
  display: block;
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  flex-direction: row;
}

.related-wrapper{
  background-color: #ececec;
  padding: 70px 0;
}

.block.related,
.block.upsell {
  .block-title.title {
    font-size: 18px;
    background: #f6f6f6;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    color: #39464f;
    margin-bottom:20px;

  }
  .block-content {
    .block-actions {
      display: none;
    }
    .field.choice.related {
      display: none;
    }
  }

  .product-item{
    float: left;
  }
}


.block.upsell{

  .slick-list {
    margin:0;
  }
  .slick-slider{
    padding: 0 47px;
  }
  .slick-track{
    margin-left: 0;
  }
  .slick-slide{
    padding: 0 10px;
  }
}
//
//  Product info tabs
//  ---------------------------------------------

.product.info.detailed {
  clear: both;
  margin-bottom: 30px;

  @include min-screen(700px) {
    .sticky > #tab-header {
      position: fixed;
      top: 74px;
      width: 100%;
      background: white;
      left: 0;
      z-index: 99;
      border-top: 1px solid #ddd;
      box-shadow: 5px 5px 15px 5px #0000004a;
      max-width:100%;

      @include max-screen($screen__s) {
        position: static;
      }

      .data.item.title {
        @include max-screen($screen__m) {
          height: 35px;
          line-height: 35px;
        }

        a.data.switch {
          @include max-screen($screen__m) {
            height: 35px !important;
            line-height: 35px !important;
            padding: 0;
            width: 100%;
          }
        }
      }

      .data.switch {
        a {
          border-top: 0 !important;
        }

        &:after {
          display: none !important;
        }
      }
    }
  }

  #tab-header{
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
    border-bottom: 1px solid #ddd;

    @include max-screen(640px) {
      flex-direction: column;
    }

    .data.item.title{
      height: 55px;
      width: 100%;

      .data.switch{
        color: #39464f;
        font-size: 17px;
        border: 1px solid #ddd;
        margin-right: 4px;
        border-bottom: 0;
        display: block;
        padding: 0 0;
        width: 100%;
        text-align: center;
        height: 55px;
        line-height: 55px;
        background: #f6f6f6;
        margin-bottom: -1px;
        position: relative;
        font-weight: 500;

        @include max-screen(640px) {
          width: 100%;
        }
      }
      &.active{
        & > .switch{
          background:white;

          &:after {
            content: "";
            width: 100%;
            height: 4px;
            background-color: #ffffff;
            position: absolute;
            top: 98%;
            left: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
  //Tab

  .additional-attributes {
    width: 100%;

    tr.title{
      clip: auto;
      margin: 0;
      width: auto;
      position: initial;
      height: auto;
      background: #253b4b !important;
      font-weight: bold;
      color: white;
      padding: 10px;
      text-align: left;
      }

    tr{
      padding: 5px 10px;

      &:nth-of-type(odd) {
        background: #0000000a;
      }
      th{
        vertical-align: middle;
        padding: 10px !important;
      }
      td{
        padding: 10px;
      }

    }

    @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
    );
  }

  .data.item.content {
    background: #ffffff;
    padding: 20px 20px 30px 20px;
    border: 0 solid #ccc;
    max-width: 1233px;
    margin: 45px auto;
    float: none;
  }
  .product.attribute.description {
    @include lib-font-size(15);

    ul {
      padding-left: $indent__base;
    }
    table {
      width: auto !important;

    }
    .css_t1 {
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;
    }
    .css_t2 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .additional-attributes-wrapper {
    display: block;
    justify-content: center;
    margin: 0 auto;

    .additional-attributes > tbody > tr > th,
    .additional-attributes > tbody > tr > td{
      padding: 10px;

      @include max-screen(638px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 5px;
        row-gap: 5px;
        display: grid;
      }
    }
    //Specificatio table styles
    @include max-screen($screen__s) {
      &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr.title td:before {
        display: none;
      }
    }
  }
  .additional-attributes {
    @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__s,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
    );

    .label {
      &:after {
        content: ":";
      }
    }
  }
}

//Specificatio table styles
@include max-screen($screen__s) {
  &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr.title td:before {
    display: none;
  }
}

//Product share links
#share-links{
  display: flex;
  align-items: center;
  gap:10px;

  span{
    display: inline-block;
    font-size: 14px;
    color: #6d6d6d;
  }
  a{
    display:inline-block;
    color: #6d6d6d;
    font-size: 20px;
    line-height: 20px;
  }
}
//
//  Added to cart popup
//  ---------------------------------------------

.add-to-cart-popup {
  .modal-inner-wrap {
    background-color: $gray-lightest__color;
    max-width: 560px;

    .modal-header,
    .modal-content,
    .modal-footer {
      padding: 3rem;
    }

    .modal-header{
      background: $primary__color;
      display: block;

      h1{
        color: white;
        border-bottom:none;
        padding:0;
        font-size:16px;
      }
    }
    .modal-content{
      & > div{
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }
    .modal-footer {
      padding-top: 0;
      font-size: 0;
      text-align: center;

      .action {
        display: inline-block;
        margin-bottom: 6px;
        margin-left: 3px;

        &.action-link{
          background: transparent;
          text-align: left;
          padding: 0 15px;
        }

        &.continue-shopping{
          &:before{
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            margin-right: 10px;
            content: "\f053";
          }
        }
      }
    }
  }
}
.amrelated-grid-wrapper{
	.block-title{
		text-align:center;
		strong{
		      font-size: 24px;
		}
	}
}
.products-grid{
  .product-item{
     display: inline-block;
     .product-item-actions{
     	.actions-secondary{
     		.tocompare{
     		  display:none;
     		}
     	}
     }
  }
}

.product-item{
  border-bottom: 0;
  padding: 20px 20px 40px 20px;
  position: relative;
  height: auto !important;

  /* Product image*/
  .product-image-container {
    display: inline-block;
    max-width: 100%;
  }
  .product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .product-item-photo{
    display:block;
    text-align: center;
    margin-bottom: 20px;
  }
  .product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  /* Product Name */
  .product-item-name{
      text-align: left;
      font-family: $font-family-name__base;
      font-size: 14px;
      display: block;
      margin-bottom: $indent__m;
      color:#39464f;
      word-wrap: break-word;
      hyphens: auto;
      overflow: hidden;
    font-weight:bold;
      }

  /* Wishlist */
  .towishlist{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    line-height: 20px !important;
    background: white;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 2px 2px #00000026;

    &:before{
      color:#39464f;
      margin-right: 0 !important;
    }

    span{
      display: none;
    }
  }

  /* Product Price*/
  .price-box {
    padding-bottom: $indent__base;
    color: $primary__color;
    display: flex;
    flex-direction: column;

    .price-container {
      white-space: nowrap;
    }
    .old-price{
      order: 2;
      line-height: 24px;

      .price-container {
        padding-top: 0;
      }
    }
    .special-price {
      order: 1;
      line-height: 24px;

      .price-container {
        padding-top: 0;
      }
    }
    .save-price {
      margin: 2px 0;
      font-size:16px;
      color:#ec1667;
    }
    .normal-price,
    .special-price {
      .price-including-tax {
        display: none;
      }
    }
  }

  /*condition*/
  .condition{
    color:#298dd8;
    font-weight: bold;
    margin-bottom:10px;
  }

  /* Productpw*/
  .product-category-uspss{
    padding-left: 15px;
    flex-grow: 1;
    list-style: disc;
    margin-bottom: 15px;

    li{
      color:#00cc00;
      margin-bottom: 5px;

      span{
        color: $primary__color;
        font-size: 13px;
      }
    }
  }

  /*  Product Item Footer  */
  .product-item-footer{
    line-height: 40px;

    form{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;

      .control{
        width: 50%;
        display: flex;
        flex: auto;

        & > * {
          flex-basis: 100%;
        }

        .btn-qty-increase{
          border-right:0px;
        }
      }

      .action.tocart{
        border: 0;
        height: 40px;
        vertical-align: baseline;
        width: 50%;
        min-width: 100px;
        flex: auto;
        color: #ffffff;
        background: #00cc00;
      }
    }


    .btn-qty{
      cursor: pointer;
    }
    .btn-qty,
    .qty{
      height: 40px;
      border-right: 1px solid #ddd;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      text-align: center;
    }
  }
}

.products-list{

  .product-item-body {
    flex-grow: 1;
  }
}
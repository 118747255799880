.products{
  .product-items{
    display: grid;
    grid-template-columns: repeat(auto-fit, 25%);

    @include max-screen($screen__xs) {
      grid-template-columns: repeat(auto-fit, 100%);
    }
    @include max-screen($screen__m) {
      grid-template-columns: repeat(auto-fit, 33.333%);
    }
    @include max-screen($screen__s) {
      grid-template-columns: repeat(auto-fit, 50%);
    }
    @include max-screen(400px) {
      grid-template-columns: repeat(auto-fit, 100%);
    }
    //Product Grid
    .product-item{
      border-bottom: 1px solid #e0e0e0;
    }

    //Product List
    .products-list{
      grid-template-columns: repeat(auto-fit, 100%);

      .product.name.product-item-name{
        font-weight:bold;
      }
    }
  }

  &.grid{
    .product-item{
      flex-direction: column;
      display: flex !important;
      flex: 1 1 200px;
      column-gap: initial;
      justify-content: space-between;
    }
  }

  &.list{
    .product-items {
      display: grid;
      grid-template-columns: repeat(auto-fit, 100%);

      .product-item{
        flex-direction: row;
        gap: 30px;
        display: flex;

        .product-item-footer{
          border-left: 1px solid #ececec;
          padding-left: 30px;

          a.action.secondary{
            width:100%;
            text-align: center;
          }
        }
      }
    }
  }
}

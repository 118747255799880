//
//  Newsletter
//  ---------------------------------------------

.page-footer {
    .newsletter-bar{
        background-color: $primary__color;
        padding: 20px 0;
        margin-bottom: 20px;

        @include max-screen($screen__s) {
            text-align: center;
        }

        .newsletter.container:before{
            content: ' ';
            border-right: 10px solid transparent;
            border-top: 16px solid #01cc01;
            display: block;
            width: 50px;
            float: left;

            @include max-screen($screen__s) {
                display: none;
            }
        }
        .block.newsletter{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 26px;
            margin: 0 auto;
            flex-wrap: wrap;
            border-bottom: 1px solid #384a55;
            padding-bottom: 23px;

            @include max-screen($screen__s) {
                padding: 0 10px;
                width: 100%;
            }

           .title{
               font-size: 24px;
               margin-bottom:0;

               strong{
                   font-weight: 500;
               }
           }
            & > *{
                gap: 26px;
                white-space: nowrap;
                color:white;
                margin-bottom:0;

                @include max-screen($screen__s) {
                    white-space: initial;
                }
            }
            & > p{
                font-size:19px;
            }
            & > div:last-child {
                min-width: 200px;
                flex-grow: 2;
            }

            form.form.subscribe{
                #newsletter{
                    background: #334958;
                    border: 1px solid #334958;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;
                    padding-left: 15px;
                    color:#fff;

                    &::placeholder,
                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &:-moz-placeholder { /* Firefox 18- */
                        color: white;
                    }
                }
                button.action.subscribe.primary{
                    margin-left: 10px;
                    height: 44px;
                    font-size: 15px;
                    padding: 0 50px;
                    background: $secondary__color;
                    border: $secondary__color;
                    text-transform: none;
                }
            }
        }
    }
    .block.newsletter {
        .title {
            margin-bottom: 20px;
        }
        .content {
            .form.subscribe {
                display: table;
                width: 100%;
                margin-bottom: 10px;

                .field.newsletter {
                    display: table-cell;

                    .label {
                        @extend .abs-visually-hidden;
                    }
                }
                .actions {
                    display: table-cell;
                    width: 1%;
                    vertical-align: top;

                    .action.primary {
                        margin-left: 10px;
                        height: 44px;
                        font-size: 15px;
                        padding: 0 50px;
                        background: $secondary__color;
                        border: $secondary__color;
                        text-transform: none;
                    }
                }
            }
        }
    }
}

//
//  Filters
//  ---------------------------------------------

.block.filter {
    margin-bottom: 10px;

    @include max-screen($screen__m) {
        margin-bottom: $indent__base;
    }

    .block-title {
        @include lib-font-size(15);
        color: $primary__color;
        margin: $indent__s 0 $indent__base;

        @include max-screen($screen__l) {
            text-align: center;
            border: 2px solid $secondary__color;
            padding:0;

            strong{
                padding: $indent__s 0;
                display: block;
            }
        }
    }

    .field.search{
        display: none;
    }
    @include max-screen($screen__l) {
        .block-content {
            display: none;
        }
    }

    .block-subtitle {
        display: none;
    }

    &.active{
        .filter-content {
            display: block;
        }
    }
    .active > .filter-options-content{
        display:block;
    }

}

.filter-current {
    .item {
        margin-bottom: 10px;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    .action.remove{
        color: #be2020;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }

    .item.Yellow,
    .item.Black,
    .item.Red,
    .item.Yellow,
    .item.Green,
    .item.White,
    .item.Cream,
    .item.Orange,
    .item.Purple,
    .item.Blue,
    .item.Brown,
    .item.Pink{
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        border: 1px solid #d7d7d7;
        width: 30px;
        padding: 3px;
        float: left;
        margin-right: 10px;
        height: 30px;
        margin-bottom: 10px;
        transition: background-color 0.5s ease;

        & > a{
            padding: 0;
            text-indent: -999px;
            overflow: hidden;
            height: 22px;
            display: block;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background-image: none;
            border: none !important;

            &:before{
                display:none !important;
            }
        }
    }
    .item.Yellow a{background-color: #ffffff;}
    .item.Black a{background-color: #000;}
    .item.Red a{background-color: #e52929;}
    .item.Yellow a{background-color: #e8ea29;}
    .item.Green a{background-color: #7ab441;}
    .item.White a{background-color: #ffffff;}
    .item.Cream a{background-color: #e6e1b3;}
    .item.Orange a{background-color: #ea9818;}
    .item.Purple a{background-color: #9749c9;}
    .item.Blue a{background-color: #428fd7;}
    .item.Pink a{background-color: #df5997;}
    .item.Brown a{background-color: #8b4d31;}

    .item.Yellow:hover{background-color: #ffffff;}
    .item.Black:hover{background-color: #000;}
    .item.Red:hover{background-color: #e52929;}
    .item.Yellow:hover{background-color: #e8ea29;}
    .item.Green:hover{background-color: #7ab441;}
    .item.White:hover{background-color: #ffffff;}
    .item.Cream:hover{background-color: #e6e1b3;}
    .item.Orange:hover{background-color: #ea9818;}
    .item.Purple:hover{background-color: #9749c9;}
    .item.Blue:hover{background-color: #428fd7;}
    .item.Pink:hover{background-color: #df5997;}
    .item.Brown:hover{background-color: #8b4d31;}
}


.filter-options {
    margin: 0;

    &-title {
        @include lib-font-size(14);
        position: relative;
        cursor: pointer;
        padding-bottom: 8px;
        color: #6d6d6d;
        font-weight: 400;
        margin-bottom: 20px;
        border-bottom: 1px solid #eaeaea;

    }
    &-content {
        @include max-screen($screen__l) {
            display: none;
        }

        .items {
            padding-bottom: $indent__s;
        }
        .item {
            position: relative;
            margin-bottom: $indent__s;
            @include lib-font-size(15);

            a {
                color: $text__color;
                position: relative;

                .count {
                    font-weight: normal;
                }
                &:hover {
                    text-decoration: none;
                }
                /*&:before {
                    width:18px;
                    height:18px;
                    display: block;
                    content: ' ';
                    border: 1px solid #d0d0d0;
                    position:absolute;
                    left: 0;
                    top: 0;
                } */
            }
        }
    }
    &-item {
        &.active {
            .filter-options-title {
                &:before {
                    display: none;
                }
            }
        }
    }
    .count {
        display:none !important;
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}


/*Filters */
.filter{
    .filter-options-content{
        box-shadow: none !important;
        border: 0 !important;
    }

    ol,ul{
        list-style: none;
        padding-left: 0;
    }

    //Clear filters
    .action.clear.filter-clear{
        margin-bottom: 10px;
        display: block;
        background: none;
        border: none;
        color: #000;
        padding: 0;
        text-decoration: underline;
    }

    //Currently selected
    .count{
        display:none !important
    }

    .amshopby-filter-current{
        .am-swatch-wrapper{
            margin-bottom: 0 !important;
        }

        .filter-current-subtitle{
            width: 100%;
            font-size: 15px;
            border-bottom: 1px solid #e1e3e4;
            padding-bottom: 7px;
            font-weight: bold;
            clear: both;
            display: block;
            margin-bottom: 5px;
        }

        .amshopby-remove{
            &:after{
                display: none;
            }

            &:before{
                border: 0;
                transform: rotate(-0deg);
                width: auto;
                height: auto;
                position: absolute;
                top: 0;
                background: transparent;
                color: red;
            }
        }

        .item {
            line-height: 22px;
            margin-bottom: 0;
            padding: 6px 0 0;
            display: flex;
            gap: 5px;

            .amshopby-remove{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 15px;
                height: 15px;

                &:before{
                    content: "x";
                    border: none;
                    background: none;
                    padding: 0;
                    position: absolute;
                    top: -2px;
                    font-size: 16px;
                    left: 4px;
                }
            }
        }
    }

    //Filter Item
    .items{
        margin-bottom: 0;

        .item{
            line-height: 18px;
            margin-bottom: 9px;
            padding-left: 0;

            //Swatches
            &.am-swatch-wrapper{
                width: 25px;
                margin: 0 17px 17px 0 !important;

                a{
                    width: 25px;
                    height: 25px;
                    padding: 0;
                    display: inline-block;

                    .swatch-option{
                        border: 0;
                        width: 25px;
                        min-width: 25px;
                        height: 25px;
                        -webkit-border-radius: 20px;
                        -moz-border-radius: 20px;
                        border-radius: 20px;
                        padding: 0;

                        &[option-label="White"]{
                            border: 1px solid #ddd;
                        }

                        &.image:not(.disabled):hover,
                        &.color:not(.disabled):hover{
                            border: 1px solid black !important;
                            outline: none;
                        }
                    }

                    &:before{
                        display: none !important;
                    }
                }
            }

            /* hide form elements */
            input[type="checkbox"],
            input[type="radio"]{
                opacity: 0;
                position: absolute;
            }

            //All links
            a {
                margin-left: 0;
                //padding-left: 22px;
                color: black;
                font-size: 13px;
                position: relative;
                display: block;
                line-height: 19px;

                //&:before {
                //    width: 12px;
                //    height: 12px;
                //    border: 1px solid #bababa;
                //    content: ' ';
                //    display: block;
                //    position: absolute;
                //    top: 3px;
                //    left: 0;
                //    -webkit-border-radius: 2px;
                //    -moz-border-radius: 2px;
                //    border-radius: 2px;
                //    background: rgb(236, 236, 236);
                //    background: -moz-linear-gradient(0deg, rgba(236, 236, 236, 1) 50%, rgba(252, 252, 252, 1) 85%, rgba(255, 255, 255, 1) 100%);
                //    background: -webkit-linear-gradient(0deg, rgba(236, 236, 236, 1) 50%, rgba(252, 252, 252, 1) 85%, rgba(255, 255, 255, 1) 100%);
                //    background: linear-gradient(0deg, rgba(236, 236, 236, 1) 50%, rgba(252, 252, 252, 1) 85%, rgba(255, 255, 255, 1) 100%);
                //    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ececec",endColorstr="#ffffff",GradientType=1);
                //}

                &.am_shopby_link_selected {
                    &:before {
                        background: #32aae5 !important;
                        border: 1px solid #32aae5 !important;
                    }
                }
            }
        }
    }
    //Show all btn
    .am-show-more {
        align-items: center;
        font-size: 13px;
        color: #00cc00;
        padding: 0;
        margin-bottom: 20px;
        margin-top: 0;
        background: white;
        display: block;
        clear: both;

        &.-disabled{
            display: none !important;
        }
        &:before,
        &:after{
            display: none;
        }
    }

    //swatches
    .swatch-attribute{
        .am-shopby-swatch-label{
            display:none;
        }
    }
    //Price Slider

    .am-filter-items-attr_price{
        padding-top: 10px;

        li:first-child{
            display: none;
        }
    }
    .ui-slider-horizontal {
        height: 8px;
        border-radius: 10px;
        border: none;
        background: #dadada;
        margin-bottom: 10px;
    }
    .amshopby-slider-container{
        .am-slider .ui-slider-handle {
            margin-top: -7px;
            border: 1px solid #00cc00;
            background: white;
            height: 20px;
            width: 20px;
            cursor: pointer;
        }
        .ui-slider-range {
            position: absolute;
            height: 8px;
            background: #e0e0e0;
        }
    }
}

.catalog-topnav .block-actions{
    display: block !important;

    a{
        display: block;
        padding: 10px;
        margin-bottom: 0 !important;
        border-bottom: 1px solid #ddd;
    }
}

$navigation-level0-item__color                  : $text__color;
$navigation-level0-item__active__border-color   : $primary__color;
$navigation-level0-item__font-weight            : normal;

$navigation-desktop__font-weight                : normal;
$navigation-desktop-level0-item__margin         : 0;
$navigation-desktop-level0-item__padding        : 2px 0 0;
$navigation-desktop-level0-item__line-height    : 19px;
$navigation-desktop-level0-item__color          : $primary__color;
$navigation-desktop-level0-item__hover__color   : $primary__color;
$navigation-desktop-level0-item__hover__border-width            : 4;
$navigation-desktop-level0-item__active__color  : $primary__color;
$navigation-desktop-level0-item__active__border-width           : 4;

$submenu-desktop__font-weight                   : 500;
$submenu-desktop-item__active__border-color     : $primary__color;
$navigation-level0-item__active__border-color            : $secondary__color;
@import 'page-builder/_import';

@include min-screen($screen__m) {
  .pagebuilder-mobile-only{
    display:none;
  }
  .pagebuilder-mobile-hidden{
    display: inline-block;
  }
}
@include max-screen($screen__m) {
  .pagebuilder-mobile-hidden{
    display:none;
  }
}
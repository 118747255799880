@import '../_utils';

.amscroll-load-button {
    display: block;
    margin: 30px auto;
    padding: 5px 20px;
    line-height: 30px;
    max-width: 100%;
    border-radius: 30px;
    background-color: transparent;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.6rem;
    cursor: pointer;
    width: 200px;
    text-align: center;
    transition: color 0.2s;
    border: 2px solid #39464f !important;

    ._keyfocus &:focus {
        box-shadow: none;
    }


    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }

    &:hover > .amscroll-text {
        filter: $amscroll__hover-filter;
    }

    .ie11 & {
        width: 120px;
    }

    & > .amscroll-text {
        transition: filter $amscroll__transition-duration;
        pointer-events: none;
        user-select: none;
    }

    & > .amscroll-loading-icon {
        display: none;
        margin-right: $amscroll__indent;
    }

    &.-amscroll-loading {
        pointer-events: none;
        user-select: none;

        & > .amscroll-loading-icon {
            display: inline-block;
            margin: 0 10px -5px 0;
        }
    }
}
[data-content-type='slide'] {
    box-sizing: border-box;
    line-height: 20px;
    min-height: inherit;
    overflow: hidden;

    & > [data-element='link'],
    & > [data-element='empty_link'] {
        color: inherit;
        min-height: inherit;
        text-decoration: inherit;

        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }

    & + [data-content-type='slide'] {
        height: 0;
        min-height: 0;

        .slick-slider & {
            height: initial;
            min-height: inherit;
        }
    }
}
.pagebuilder-slide-wrapper {
        border-radius: inherit;
        box-sizing: border-box;
        min-height: inherit;
        overflow-wrap: break-word;
        word-wrap: break-word;

        .jarallax-viewport-element {
            height: 100%;
            left: -15000vw;
            position: absolute;
            top: 0;
            width: 1px;
            z-index: 100;

            .slick-current & {
                left: 0;
            }
        }

        &.jarallax {
            .video-overlay {
                -webkit-transform: unset;
                z-index: 1;
            }

            .pagebuilder-overlay {
                position: relative;
                z-index: 2;
            }

            [id*='jarallax-container'] {
                > div,
                > img,
                > video,
                > iframe {
                    margin: auto !important;
                    transform: none !important;
                }
            }
        }

        .pagebuilder-overlay {
            -moz-transition: background-color 500ms ease;
            -o-transition: background-color 500ms ease;
            -webkit-transition: background-color 500ms ease;
            border-radius: inherit;
            box-sizing: border-box;
            padding: 30px;
            transition: background-color 500ms ease;

            &.pagebuilder-poster-overlay {
                align-items: center;
                display: flex;
                justify-content: center;
                min-height: inherit;
            }

            &:not(.pagebuilder-poster-overlay) {
                max-width: 540px;
            }
        }

        [data-element='content'] {
            min-height: 50px;
        }

        .pagebuilder-slide-button {
            -moz-transition: opacity 500ms ease;
            -o-transition: opacity 500ms ease;
            -webkit-transition: opacity 500ms ease;
            margin: 20px 0 0 0;
            max-width: 100%;
            text-align: inherit;
            transition: opacity 500ms ease;
            word-break: break-word;
        }
    }


@include max-screen($screen__m) {
    .pagebuilder-slide-wrapper {
        .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
            max-width: none;
        }
    }
}

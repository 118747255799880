//
//  Variables
//  ____________________________________________

$amscroll-module__path: '../Amasty_Scroll';
$amscroll__transition-duration: .2s;
$amscroll__hover-filter: brightness(.8);

//
//  Colors
//  --------------------------------------------

$amscroll__text__color: #252525;
$amscroll__border__color: #c2c2c2;
$amscroll__background-color: #fbfbfb;

//
//  Indents
//  --------------------------------------------

$amscroll__indent: 10px;
$amscroll__indent__m: $amscroll__indent + $amscroll__indent / 2;
$amscroll__indent__l: $amscroll__indent * 2;
$amscroll__indent__xl: $amscroll__indent__l + $amscroll__indent / 2;

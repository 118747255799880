

//Hide gallery untill initialized
.gallery__items.js-gallery,
.gallery__thumbnails.js-gallery-thumbnails{
  display:none;
}

.gallery__items.js-gallery.slick-initialized,
.gallery__thumbnails.js-gallery-thumbnails.slick-initialized{
  display:block;
}

//Gallery wrap
.gallery{
  margin-bottom:50px;
}

//Thumbnails gallery
.gallery__thumbnails{
  padding: 0 80px;

  //Navigation arrows
  .slick-arrow{
    width: 43px;
    height:43px;
    border:1px solid #6a6a6a;
    border-radius: 50px;

    &:before,
    &:after{
      width: 12px;
      height: 2px;
      background: #6f6f6f;
      content: ' ';
      display: block;
      position: absolute;
      left: 16px;
      top: 16px;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
      top: 23px;
    }

    &.slick-prev{
      &:before{
        transform: rotate(-45deg);
        left: 14px;
      }
      &:after{
        transform: rotate(45deg);
        top: 23px;
        left: 14px;
      }
    }
  }
  //Image

  .slick-slide{
    padding: 4px;

    span{
      display:block;
      padding:5px;
      border:1px solid #e1e1e1;
    }

    &.slick-current{
      span{
        border:1px solid #889095;
      }
    }
  }

}
@media all and (min-width: 640px) {
  .checkout-index-index {
    .opc-wrapper.am-opc-wrapper .amcheckout-delivery-date .field {
      display: block;
      width: 50%;

      &:nth-child(2n) {
        padding-left: 7px;
      }

      &.datepicker .control, &.timepicker .control, &.comment {
        width: 100%;
      }
    }

    .modal-popup.modal-slide:not(.agreements-modal):not(.popup-authentication) .modal-inner-wrap {
      width: 500px;
    }
  }
}

@media all and (min-width: 1024px), print {
  .checkout-index-index .opc-wrapper.am-opc-wrapper .shipping-address-item {
    width: 50%;
  }

  #checkout.am-checkout.-modern.-layout-1column .authentication-wrapper {
    min-width: 300px;
  }

  .amcheckout-main-container.-modern {
    .amcheckout-step-container {
      .amcheckout-form-login .amcheckout-email .amcheckout-wrapper {
        -webkit-box-flex: inherit;
        -ms-flex: inherit;
        flex: inherit;
        width: calc(50% - 7px);
      }

      .amcheckout-delivery-date .field {
        &.date, &.timepicker {
          width: 50%;
        }

        &.date {
          padding-right: 10px;
        }

        &.timepicker {
          padding-left: 10px;
        }
      }

      .amcheckout-summary-container {
        .amcheckout-title {
          background: #eee;
          color: #363636;

          > .amcheckout-icon {
            background: none;
          }
        }

        .subtotal {
          width: 15%;
        }

        .table-totals {
          margin-left: auto;
          width: 40%;

          tbody{
            display: flex;
            width: 100%;
            flex-direction: column;

            tr{
              display: flex;

              &.grand.totals.incl{
                order: 2;
                th,td{
                  padding-top:0;
                }
              }
              &.grand.totals.excl{
                order: 1;
                th,td{
                  padding-top:20px;
                }
              }
              th{
                flex-grow: 1;

              }
            }
          }
        }
      }

      .amcheckout-additional-options {
        .amcheckout-checkboxes, .amcheckout-comment {
          display: inline-block;
          vertical-align: top;
        }

        .amcheckout-checkboxes {
          box-sizing: border-box;
          padding-right: 10px;
          width: 35%;
        }

        .amcheckout-comment {
          width: 65%;
        }
      }

      .checkout-payment-method .payment-option-title {
        border: none;
      }
    }

    &.-layout-1column .amcheckout-summary-container .details-qty {
      max-width: 20%;
    }

    &.-layout-2columns {
      position: relative;

      .amcheckout-column {
        margin: 0;

        &.-main {
          float: left;
          width: calc(100% - (380px + 60px));
        }

        &.-sidebar {
          float: right;
          width: 380px;
          transition: all .3s ease;

          &.-sticky {
            overflow: auto;
            max-height: 100vh;
          }
        }
      }

      .amcheckout-step-container.-summary {
        border: 1px solid #d4d4d4;
        background: #fff;
      }
    }

    &.-layout-3columns .amcheckout-step-container.-summary {
      border: 1px solid #d4d4d4;
      background: #fff;
    }

    &.-layout-2columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar, &.-layout-3columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar {
      margin-bottom: 20px;
      text-align: center;
    }

    &.-layout-2columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout, &.-layout-3columns .amcheckout-step-container.-summary > .checkout-payment-method .actions-toolbar .amasty.checkout {
      float: none;
      width: 90%;
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-summary-container, &.-layout-3columns .amcheckout-step-container .amcheckout-summary-container {
      background: #f9f9f9;
      cursor: default;

      .amcheckout-title {
        background: #f9f9f9;
        cursor: default;
      }
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-summary-container .product-item-name, &.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .product-item-name {
      width: 100%;
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-summary-container .subtotal, &.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .subtotal {
      width: auto;
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-summary-container .table-totals, &.-layout-3columns .amcheckout-step-container .amcheckout-summary-container .table-totals {
      margin-left: 0;
      width: 100%;
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-additional-options, &.-layout-3columns .amcheckout-step-container .amcheckout-additional-options {
      background: #fff;
    }

    &.-layout-2columns .amcheckout-step-container .amcheckout-additional-options {
      .amcheckout-checkboxes, .amcheckout-comment {
        display: block;
        width: 100%;
      }
    }

    &.-layout-3columns .amcheckout-step-container .amcheckout-additional-options {
      .amcheckout-checkboxes, .amcheckout-comment {
        display: block;
        width: 100%;
      }
    }
  }

  .amcheckout-sticky-stopper {
    display: block;
    visibility: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 0;
  }

  .amcheckout-main-container.-modern.-layout-3columns {
    display: flex;
    justify-content: space-between;

    .amcheckout-column {
      width: 32%;
    }

    .amcheckout-step-container {
      .amcheckout-title {
        cursor: default;
      }

      .amcheckout-form-login .amcheckout-email .amcheckout-wrapper, .form-login .amcheckout-email .amcheckout-wrapper {
        flex: auto;
        width: inherit;
      }

      .amcheckout-form-login .amcheckout-password, .form-login .amcheckout-password {
        width: 100%;
      }

      .amcheckout-shipping-address {
        .shipping-address-item, .shipping-address-details {
          width: 100%;
        }

        .shipping-address-item:not(:last-of-type), .shipping-address-details:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      .amcheckout-shipping-methods {
        min-width: inherit;

        .amcheckout-method {
          width: 100%;
          margin-left:0;
          margin-right:0;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }

      .billing-address-details {
        width: 100%;
      }

      .amcheckout-payment-methods .payment-method:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .amcheckout-delivery-date .field.date {
        .control {
          position: relative;
        }

        .input-text._has-datepicker {
          width: 100%;
        }

        ._has-datepicker ~ .ui-datepicker-trigger {
          position: absolute;
          top: 7px;
          right: 7px;
          margin: 0;
        }
      }
    }
  }
}

._amcheckout-selectable-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.amcheckout-step-container {
  .shipping-address-items, .amcheckout-shipping-methods .amcheckout-items, .checkout-payment-method .amcheckout-payment-methods .amcheckout-content > .amcheckout-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

._amcheckout-selectable-item {
  display: block;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border: 1px solid #f9f9f9;
  border-radius: 2px;
  background: #f9f9f9;
  cursor: pointer;
}

.amcheckout-step-container {
  .amcheckout-shipping-address {
    .billing-address-details, .shipping-address-details {
      display: block;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
      border: 1px solid #f9f9f9;
      border-radius: 2px;
      background: #f9f9f9;
      cursor: pointer;
    }
  }

  .shipping-address-items .shipping-address-item, .amcheckout-shipping-methods .amcheckout-method, .checkout-payment-method .amcheckout-payment-methods .payment-method {
    display: block;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border: 1px solid #f9f9f9;
    border-radius: 2px;
    background: #f9f9f9;
    cursor: pointer;
  }
}

._amcheckout-selectable-item:not(:last-of-type) {
  margin-bottom: 15px;
}

.amcheckout-step-container {
  .amcheckout-shipping-address {
    .billing-address-details:not(:last-of-type), .shipping-address-details:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  .shipping-address-items .shipping-address-item:not(:last-of-type), .amcheckout-shipping-methods .amcheckout-method:not(:last-of-type), .checkout-payment-method .amcheckout-payment-methods .payment-method:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

._amcheckout-selectable-item:hover {
  box-shadow: 0 2px 8px rgba(56, 77, 108, 0.4);
}

.amcheckout-step-container {
  .amcheckout-shipping-address {
    .billing-address-details:hover, .shipping-address-details:hover {
      box-shadow: 0 2px 8px rgba(56, 77, 108, 0.4);
    }
  }

  .shipping-address-items .shipping-address-item:hover, .amcheckout-shipping-methods .amcheckout-method:hover, .checkout-payment-method .amcheckout-payment-methods .payment-method:hover {
    box-shadow: 0 2px 8px rgba(56, 77, 108, 0.4);
  }
}

._amcheckout-selectable-item.-selected {
  border-color: #253b4b;
}

.amcheckout-step-container {
  .amcheckout-shipping-address {
    .billing-address-details.-selected, .shipping-address-details.-selected {
      border-color: #253b4b;
    }
  }

  .shipping-address-items .shipping-address-item {
    &.-selected, &.selected-item {
      border-color: #253b4b;
    }
  }

  .amcheckout-shipping-methods .amcheckout-method.-selected {
    border-color: #253b4b;
  }

  .checkout-payment-method .amcheckout-payment-methods .payment-method {
    &.-selected, &._active {
      border-color: #253b4b;
    }
  }
}

@media all and (min-width: 768px) {
  ._amcheckout-selectable-item {
    width: 48%;
  }

  .amcheckout-step-container {
    .amcheckout-shipping-address {
      .billing-address-details, .shipping-address-details {
        width: 48%;
      }
    }

    .shipping-address-items .shipping-address-item, .amcheckout-shipping-methods .amcheckout-method, .checkout-payment-method .amcheckout-payment-methods .payment-method {
      width: 48%;
    }
  }

  ._amcheckout-selectable-item:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  .amcheckout-step-container {
    .amcheckout-shipping-address {
      .billing-address-details:nth-last-child(-n + 2), .shipping-address-details:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }

    .shipping-address-items .shipping-address-item:nth-last-child(-n + 2), .amcheckout-shipping-methods .amcheckout-method:nth-last-child(-n + 2), .checkout-payment-method .amcheckout-payment-methods .payment-method:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }
}

.checkout-index-index {
  .authentication-wrapper {
    margin-right: 9px;
  }

  .checkout-header {
    margin: 12px 0 20px;
    padding: 0 9px;
    width: 70%;

    .title {
      margin: 0 auto 7px;
    }

    .description {
      font-size: 18px;
    }
  }

  legend.label {
    display: none !important;
  }

  .opc-wrapper.am-opc-wrapper {
    float: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;

    &.layout-2columns .checkout-column {
      width: 100%;
    }

    &.layout-3columns {
      .checkout-column {
        width: 100%;
      }

      .shipping-address-item {
        &:nth-child(3n + 1):before {
          display: block;
        }

        &:nth-child(2n + 1):before {
          display: none;
        }

        width: 50%;
      }
    }

    &.layout-2-3-columns .shipping-address-item {
      width: 50%;
    }

    &.layout-3columns .shipping-address-item:only-child, &.layout-2-3-columns .shipping-address-item:only-child {
      width: 100%;
    }

    &.layout-3columns .table-checkout-shipping-method, &.layout-2-3-columns .table-checkout-shipping-method {
      width: 100%;
      min-width: 0;
    }

    &:before {
      display: none;
    }

    .step-content {
      margin: 0;
    }

    .shipping-address {
      margin-bottom: 0;
    }

    #co-payment-form {
      .discount-code, .rewards-add, .gift-code {
        display: none;
      }
    }

    .discount-code .payment-option-title {
      border: none;
    }

    .checkout-agreement {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      label {
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
      }

      div.mage-error {
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
      }

      .action-show {
        text-align: left;
      }
    }

    .payment-method-content {
      padding: 0 22px 20px;
    }

    .payment-option-content {
      padding: 0 22px 20px;

      .action-apply {
        margin-right: 0;
      }
    }

    .payment-method-content .actions-toolbar > .primary {
      padding-right: 0;
    }

    .checkout-block > li {
      list-style-type: none;
    }

    .field.amcheckout-comment {
      margin-bottom: 10px;
    }

    .label {
      word-break: break-all;
      word-wrap: break-word;
    }

    .checkout-billing-address {
      max-width: 500px;

      .billing-address-same-as-shipping-block {
        margin: 5px 0 10px;
      }

      .actions-toolbar {
        margin-top: 15px;

        .action.action-cancel {
          margin: 0 20px 0 0;
        }
      }

      .primary {
        float: right;
      }

      .field-select-billing .label {
        display: none;
      }
    }

    .checkout-shipping-address .primary {
      float: right;
    }
  }

  .am-submit-summary #checkout-payment-method-load {
    .actions-toolbar {
      display: none;
    }

    .payment-method-billing-address .actions-toolbar {
      display: block;
    }
  }

  .am-submit-fixed {
    #checkout-payment-method-load .actions-toolbar {
      display: block;
      position: fixed;
      z-index: 999;
      width: 1240px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      background-color: #eaeaea;
      left: 50%;
      transform: translate(-50%);

      > * {
        float: none;
      }
    }

    &.-top #checkout-payment-method-load .actions-toolbar {
      bottom: auto;
      top: 0;
    }

    &.-bottom #checkout-payment-method-load .actions-toolbar {
      bottom: 0;
    }
  }

  .checkout-block {
    margin: 7px 9px 32px;
    padding: 24px 12px;
    background: white;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.15);
    -webkit-transition: box-shadow 0.5s;
    -moz-transition: box-shadow 0.5s;
    -ms-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 0 1px 4px 1px rgba(25, 121, 195, 0.45);
    }
  }

  .am-checkout {
    margin: 20px 0;
    padding: 12px;

    &:not(.-modern) {
      background: #eaeaea;
    }
  }

  .opc-block-summary {
    margin: -24px -12px 30px;
    padding: 24px 12px;

    .minicart-items-wrapper {
      margin: 0;
    }
  }

  .additional-options {
    margin: 24px 7px 12px;

    .checkout-agreements .action-show {
      line-height: 1.42857143;
      padding: 0;
      color: #006bb4;
      text-decoration: none;
      background: none;
      border: 0;
      display: inline;
      font-weight: 400;
      border-radius: 0;

      &:not(:focus) {
        box-shadow: none;
      }
    }

    .field {
      margin-top: 7px;
    }
  }

  .am-gift-message {
    .edit-link {
      display: none;
      color: #1979c3;
      cursor: pointer;
    }

    &.checked .edit-link {
      display: inline;
    }
  }

  .amcheckout-form-login .mage-error:not([style*='display: none;']) + .field-error {
    display: none;
  }

  .amcheckout-delivery-date {
    .field {
      float: left;
      padding-top: 4px;
      width: 100%;
      box-sizing: border-box;

      &.comment {
        width: 100%;
      }

      &.datepicker, &.timepicker {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
      }

      &.datepicker .control, &.timepicker .control {
        -webkit-flex: none;
        -moz-flex: none;
        -ms-flex: none;
        flex: none;
        width: 60%;
      }

      &.datepicker .control .ui-datepicker-trigger {
        margin-left: 7px;
      }

      .label {
        display: block;
        margin: 12px 0;
      }
    }

    .clearer {
      clear: both;
    }

    .field._required > .label:after {
      margin: 0 0 0 5px;
      font-size: 1.2rem;
      content: '*';
      color: #e02b27;
    }
  }

  .additional-options .field._required .label:after {
    margin: 0 0 0 5px;
    font-size: 1.2rem;
    content: '*';
    color: #e02b27;
  }

  .opc-block-summary .minicart-items {
    input {
      &[type='number'] {
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
          -webkit-appearance: inner-spin-button !important;
        }

        &:focus {
          -moz-appearance: spinner !important;
        }
      }

      &.qty {
        width: 55px;
      }
    }

    button {
      margin-top: 12px;
      float: right;
    }

    .delete {
      float: left;
      padding: 3px;
      width: 7px;
      height: 7px;
      border: 1px #b6b6b6 solid;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoMTJ2MTJILTF6Ii8+PHBhdGggZmlsbD0iI2I1YjViNSIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkPSJNNiA1bDQtNC0xLTEtNCA0LTQtNC0xIDEgNCA0LTQgNCAxIDEgNC00IDQgNCAxLTEtNC00eiIgc3Ryb2tlPSJudWxsIi8+PC9zdmc+) no-repeat center;
      background-size: 7px;
      cursor: pointer;
    }

    .product-item-details {
      padding-left: 118px;
    }

    .product-image-container {
      margin-left: 16px;
    }
  }

  #opc-shipping_method #checkout-shipping-method-load .table-checkout-shipping-method {
    max-width: 600px;

    .col-price {
      min-width: unset;
    }
  }

  .modal-popup:not(.agreements-modal):not(.popup-authentication) .modal-inner-wrap {
    right: 0;
    left: 0;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 900px) {
  .checkout-index-index .opc-wrapper.am-opc-wrapper {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    &.layout-2columns .checkout-column {
      width: 50%;
    }

    &.layout-3columns .checkout-column {
      width: 33.3%;
    }
  }
}

.checkout-payment-method {
  .payment-methods {
    margin: 0;

    .actions-toolbar .primary {
      padding: 14px 17px;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  &.submit .payment-methods {
    margin: 0;
  }
}

.fieldset.address {
  margin: 0 -7px;

  > .field {
    display: inline-block;
    padding: 0 7px;
    width: 100%;
    vertical-align: top;
    box-sizing: border-box;
  }
}

.checkout-success .success-messages {
  margin-bottom: 40px;
  font-size: 1.8rem;
}

.account .column.main .order-details-items {
  margin-bottom: 0;

  .order-items .data.table > tbody > tr:nth-child(even) > td {
    background: none;
  }
}

.page-main .block {
  &.block-order-details {
    margin-bottom: 25px;

    .delivery {
      float: right;
      font-weight: bold;

      .delivery-field {
        float: left;
        margin-left: 12px;
      }
    }

    .order-date {
      padding-bottom: 25px;
      border-bottom: 1px solid #c6c6c6;
      font-size: 1.6rem;
    }
  }

  &.block-order-details-comments {
    margin-bottom: 35px;
  }

  &.block-order-details-view {
    margin-bottom: 10px;

    &:nth-child(2) .block-title {
      display: none;
    }
  }
}

#registration {
  position: relative;
  margin: 20px 0 25px;
  padding: 0 0 0 12px;
  width: 600px;
  border-left: 3px solid #f58c12;

  br {
    display: none;
  }

  form {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.opc-estimated-wrapper .minicart-wrapper {
  display: none;
}

.am-checkout.-modern {
  box-sizing: border-box;
  padding: 0;
  background: none;

  &.-layout-1column {
    margin: auto;
    max-width: 750px;
  }

  .checkout-header {
    padding: 0;
  }

  .authentication-wrapper {
    margin-right: 0;

    .action-auth-toggle {
      padding: 5px 20px;
      height: auto;
      border-radius: 2px;
      background: #253b4b;
      color: #fff;
    }
  }

  .opc-estimated-wrapper {
    margin: 0 -15px 15px;
  }
}

.amcheckout-main-container.-modern {
  clear: both;
  color: #363636;

  .amcheckout-block:not(:first-of-type) {
    margin: 40px 0 0;
  }
}

.amcheckout-step-container {
  font-size: 16px;

  > li {
    list-style: none;
  }

  .amcheckout-title {
    display: block;
    margin: 0;
    padding: 15px;
    background: #eee;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;

    > .amcheckout-icon {
      position: relative;
      float: right;
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      border: 2px solid #253b4b;;
      border-radius: 50%;
      transition: all .3s ease;

      &:hover {
        border-color: #253b4b;

        &:before {
          border-color: #253b4b;
        }
      }

      &:before {
        position: absolute;
        top: 41%;
        left: 50%;
        display: block;
        box-sizing: border-box;
        width: 8px;
        height: 8px;
        border-right: 2px solid #253b4b;
        border-bottom: 2px solid #253b4b;
        content: '';
        transition: all .3s ease;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &.-minus {
        transform: rotate(180deg);
      }
    }

    + .amcheckout-content > .amcheckout-wrapper {
      padding: 20px 0;
    }
  }

  .fieldset {
    &:last-child {
      margin-bottom: 0;
    }

    > {
      .legend + br {
        display: none;
      }

      .field > .label, .fields > .field > .label {
        font-weight: normal;
      }
    }
  }

  .opc-block-summary {
    margin: 0;
    padding: 0;
  }

  .checkout-payment-method {
    .field-select-billing, .billing-address-form {
      max-width: inherit;
    }

    .checkout-billing-address {
      margin-top: 15px;
    }
  }

  .note {
    color: #777;
  }

  .amcheckout-button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger), button:not(.braintree-googlepay-button):not(.ui-datepicker-trigger) {
    border: none;
    border-radius: 2px;
    background: #253b4b;
    box-shadow: none;
    color: #fff;
  }

  .amcheckout-button:not(.braintree-googlepay-button):hover, button:not(.braintree-googlepay-button):hover {
    border: none;
  }

  .amcheckout-button.-new-address {
    margin-top: 20px;
  }

  .payment-method-content > .actions-toolbar > .primary .action.primary {
    float: none;
    margin: 0 15px 0 0;
    padding: 12px 16px;
    font-size: 16px;
  }

  .action-edit-address {
    margin-top: 15px;
  }

  .primary .action.checkout, .action.checkout.amasty {
    padding: 14px 30px;
    background: #00cc00;
  }

  .form-discount{
    display: flex;
    justify-content: space-between;

    .payment-option-inner{
      flex-grow: 1;
      margin-right: 10px;

      .input-text{
        height: 42px;
      }
    }
    .actions-toolbar .action {
      min-height: 40px;
    }
  }

  input {
    &[type='number']:focus {
      -moz-appearance: none !important;
    }

    &[type='text'], &[type='password'], &[type='url'], &[type='tel'], &[type='search'], &[type='number'], &[type='datetime'], &[type='email'] {
      height: 40px;
      border: 1px solid #d4d4d4;
      border-radius: 2px;
      font-size: 16px;
    }
  }

  select {
    height: 40px;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    font-size: 16px;
  }

  .checkout-agreements .action-show {
    background: inherit;
    color: inherit;
    font-size: inherit;
    height: auto;
  }

  .action.checkout.amasty {
    height: auto;
  }

  input {
    &[type='checkbox'], &[type='radio'] {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      top: unset;
      left: unset;
    }

    &[type='checkbox'] + label, &[type='radio'] + label {
      display: flex;
      cursor: pointer;
    }

    &[type='checkbox'] + label span:only-child, &[type='radio'] + label span:only-child {
      flex: auto;
    }

    &[type='checkbox'] + label:before, &[type='radio'] + label:before {
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 15px 0 0;
      min-width: 15px;
      min-height: 15px;
      width: 15px;
      height: 15px;
      border: 2px solid #ccc;
      background: #fff !important;
      content: '';
      cursor: pointer;
    }

    &[type='checkbox'] + label:hover:before, &[type='radio'] + label:hover:before, &[type='checkbox']:checked + label:before, &[type='radio']:checked + label:before {
      border-color: #1979c3;
      box-shadow: 0 2px 4px rgba(63, 156, 215, 0.4);
    }

    &[type='checkbox'] {
      + label:before {
        border-radius: 2px;
      }

      &:checked + label:before {
        border-width: 0;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iIzE5NzlDMyIvPjxwYXRoIGQ9Ik01IDEwbDQgNCA2LTciIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=) center no-repeat !important;
        background-size: contain !important;
      }
    }

    &[type='radio'] {
      + label:before {
        border-radius: 50%;
        transition: all .2s ease;
      }

      &:checked + label:before {
        border-width: 5px;
      }
    }
  }

  .amcheckout-form-login, .form-login {
    margin-bottom: 30px;
    border-bottom: 1px solid #d4d4d4;
  }

  .amcheckout-form-login .amcheckout-email, .form-login .amcheckout-email {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .amcheckout-form-login .amcheckout-email .amcheckout-wrapper, .form-login .amcheckout-email .amcheckout-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .amcheckout-form-login .amcheckout-password, .form-login .amcheckout-password {
    width: calc(50% - 7px);
  }

  .amcheckout-form-login .amcheckout-email .amcheckout-wrapper .input-text, .form-login .amcheckout-email .amcheckout-wrapper .input-text {
    margin: 0;
    width: 100%;
  }

  .amcheckout-form-login .amcheckout-email .field-tooltip, .form-login .amcheckout-email .field-tooltip {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 40px;
  }

  .amcheckout-form-login .amcheckout-email .note, .form-login .amcheckout-email .note {
    margin-left: auto;
    color: #363636;
    line-height: 40px;
  }

  .amcheckout-form-login .amcheckout-password, .form-login .amcheckout-password {
    display: inline-block;
  }

  .amcheckout-form-login .amcheckout-password.-confirm, .form-login .amcheckout-password.-confirm {
    float: right;
  }

  .amcheckout-form-login .actions-toolbar, .form-login .actions-toolbar {
    margin-bottom: 20px;
  }

  .amcheckout-shipping-address {
    .checkout-billing-address {
      margin-top: 20px;
    }

    .billing-address-same-as-shipping-block, .billing-address-form, .shipping-address-form {
      margin-bottom: 10px;
    }

    .billing-address-details, .shipping-address-details {
      padding: 20px;
    }

    .billing-address-details:empty, .shipping-address-details:empty {
      display: none;
    }

    .amcheckout-button {
      margin-top: 20px;

      &.-select {
        float: right;
        border: 1px solid #1787e0;
        background: #ddf0ff;
        color: #1979c3;

        &:hover {
          background: #1787e0;
          color: #fff;
        }
      }

      &.-edit {
        padding-left: 0;
        background: none;
        color: #4e4e4e;
        font-size: 16px;
      }
    }
  }

  .checkout-billing-address .amcheckout-button.-edit {
    padding-left: 0;
    background: none;
    color: #4e4e4e;
    font-size: 16px;
  }

  .amcheckout-shipping-address .amcheckout-button.-edit:hover, .checkout-billing-address .amcheckout-button.-edit:hover {
    color: #1979c3;
  }

  .amcheckout-shipping-address .amcheckout-button.-edit:before, .checkout-billing-address .amcheckout-button.-edit:before {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi42MTMgMGwtMS43MDYgMS43MTYgMi4zODggMi40TDE1IDIuNDAzIDEyLjYxMyAwek0xLjcgMTAuOThsMi4zODYgMi40IDguMTg3LTguMjMzLTIuMzg3LTIuNDAxTDEuNyAxMC45OHpNMCAxNWwyLjg5LS43NjMtMi4xMy0yLjE0NEwwIDE1eiIgZmlsbD0iIzRFNEU0RSIvPjwvc3ZnPg==) center no-repeat;
    background-size: contain;
    content: '';
  }

  .amcheckout-shipping-address .amcheckout-button.-edit:hover:before, .checkout-billing-address .amcheckout-button.-edit:hover:before {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMi42MTMgMGwtMS43MDYgMS43MTYgMi4zODggMi40TDE1IDIuNDAzIDEyLjYxMyAwek0xLjcgMTAuOThsMi4zODYgMi40IDguMTg3LTguMjMzLTIuMzg3LTIuNDAxTDEuNyAxMC45OHpNMCAxNWwyLjg5LS43NjMtMi4xMy0yLjE0NEwwIDE1eiIgZmlsbD0iIzE5NzlDMyIvPjwvc3ZnPg==) center no-repeat;
    background-size: contain;
  }

  .shipping-address-items .shipping-address-item {
    padding: 20px;
    line-height: 30px;

    &.selected-item .amcheckout-button.-select {
      visibility: hidden;
    }
  }

  .amcheckout-shipping-methods {
    display: block;
    max-width: inherit !important;

    .amcheckout-header {
      display: none;
    }

    .amcheckout-method {
      padding: 0 20px;

      .col {
        border: none;
      }

      .col-comment .field-tooltip {
        position: relative;

        &.-error {
          .field-tooltip-action:before, .field-tooltip-content {
            color: #c00815;
          }
        }
      }
    }
  }

  .checkout-billing-address .actions-toolbar {
    margin-top: 15px;

    > .primary {
      float: none;
      padding: 10px 20px;

      .action {
        margin-top: 0;
      }
    }

    .action-cancel {
      background: none;
      color: #1979c3;
    }
  }

  .amcheckout-delivery-date .field {
    width: 100%;

    &.timepicker {
      display: block;

      .control {
        width: 100%;
      }
    }
  }

  .checkout-payment-method .amcheckout-payment-methods {
    margin: 0;

    .payment-group {
      + .payment-group .step-title {
        margin: 0;
      }

      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }

    .payment-method {
      padding: 0;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    .payment-method-title {
      padding: 15px;
      border: none !important;

      .label {
        padding: 20px;
        align-items: flex-start;
        display: block;

        > *:not(:only-child):not(:last-of-type) {
          margin-right: 5px;
        }

        .action-help {
          margin-left: auto;
          text-align: right;
        }
      }
    }

    .payment-method {
      .payment-method-content {
        display: none;
      }

      &._active .payment-method-content {
        display: block;
        padding: 0 20px 0 45px;

        > * {
          margin: 0;

          > * {
            margin: 0 0 20px;
          }
        }

        .amazon-button-container__cell {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .payment-method-content .actions-toolbar > .primary {
      float: left;
      padding-left: 0;
    }
  }

  .amcheckout-summary-container {
    background: none;

    .amcheckout-title {
      background: #315499;
      color: #fff;

      > .amcheckout-icon {
        background: #fff;
      }
    }

    .amcheckout-content > .amcheckout-wrapper {
      padding: 20px;
    }

    .items-in-cart {
      margin-bottom: 20px;
      border-bottom: 1px solid #d4d4d4;
    }

    .product-item {
      .delete {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        box-sizing: border-box;
        padding: 0;
        width: 10px;
        height: 15px;
        border: 0;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjE2NyAxNWg3LjU1OGwuOTUtOC45NThILjI2TDEuMTY3IDE1em01Ljg5LTEyLjc3MlYuNTEyQS41MS41MSAwIDAgMCA2LjU1MiAwaC0zLjIxYy0uMjggMC0uNTA1LjIzLS41MDUuNTEyVjIuMmMtMS4wODUuMTIzLTEuNzExLjMxNS0yLjgzNi43MjRWNC41bDEwIC4xMThWM3MtLjk1NC0uNTM0LTIuOTQyLS43NzJ6TTYuMDUgMi4xMzRhMTguMDggMTguMDggMCAwIDAtMi4yNTgtLjAxNXYtLjkwNWMwLS4xNC4xMTEtLjI1NS4yNTItLjI1NWgxLjc1MmMuMTQxIDAgLjI1NC4xMTUuMjU0LjI1NXYuOTJ6IiBmaWxsPSIjNTc1ODU1Ii8+PC9zdmc+) center no-repeat;
        background-size: contain;

        &:hover {
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xLjE2NyAxNWg3LjU1OGwuOTUtOC45NThILjI2TDEuMTY3IDE1em01Ljg5LTEyLjc3MlYuNTEyQS41MS41MSAwIDAgMCA2LjU1MiAwaC0zLjIxYy0uMjggMC0uNTA1LjIzLS41MDUuNTEyVjIuMmMtMS4wODUuMTIzLTEuNzExLjMxNS0yLjgzNi43MjRWNC41bDEwIC4xMThWM3MtLjk1NC0uNTM0LTIuOTQyLS43NzJ6TTYuMDUgMi4xMzRhMTguMDggMTguMDggMCAwIDAtMi4yNTgtLjAxNXYtLjkwNWMwLS4xNC4xMTEtLjI1NS4yNTItLjI1NWgxLjc1MmMuMTQxIDAgLjI1NC4xMTUuMjU0LjI1NXYuOTJ6IiBmaWxsPSIjMTk3OWMzIi8+PC9zdmc+) center no-repeat;
          background-size: contain;
        }
      }

      .action.checkout {
        float: left;
        margin-bottom: 10px;
      }

      .product {
        padding-bottom: 15px;
      }
    }

    .minicart-items {
      .product-image-container {
        margin: 0;
        border: 1px solid #d4d4d4;
      }

      .product-item-details {
        padding-left: 100px;
      }

      .minicart-items-wrapper {
        padding: 15px 0 0;
      }
    }

    .product-item-details {
      .product-item-inner {
        display: flex;
        align-items: center;
      }

      .product-item-name-block {
        display: flex;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 20px 10px 0;
      }

      .action.checkout {
        border: 1px solid #1787e0;
        border-radius: 2px;
        background: #f0f8ff;
        color: #1787e0;

        &:hover {
          background: #e6f3ff;
        }
      }

      .options {
        padding-right: 20px;
      }
    }

    .product-item-name {
      margin: 0;
    }

    .details-qty {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0 0;

      .label {
        margin-right: 10px;
      }

      .qty {
        height: 30px;
      }
    }

    .subtotal {
      width: auto;
    }

    .field.configurable {
      width: 50%;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    .table-totals {
      width: 100%;
      border: none;
      font-size: 14px;

      .grand {
        .mark, .amount {
          border-top: none;
        }
      }
    }

    .item-options .label {
      display: block;
      margin-bottom: 10px;
    }
  }

  .amcheckout-additional-options {
    margin: 0;
    padding: 0 20px;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    font-size: 0;

    > * {
      font-size: 14px;
    }

    .amcheckout-checkboxes, .amcheckout-comment {
      margin: 20px 0;
    }

    .amcheckout-checkboxes > * {
      margin-bottom: 5px;
    }

    .checkout-agreement .action-show {
      text-align: left;
    }
  }

  .opc-payment-additional {
    padding: 0 20px;

    .payment-option-title, .payment-option-content {
      padding-right: 0;
      padding-left: 0;
    }

    .label {
      display: block;
      margin-bottom: 4px;
    }

    .form-discount .label {
      display: none;
    }
  }
}

.amcheckout-main-container.-layout-2columns .amcheckout-column:not(:first-of-type) {
  margin-top: 40px;
}


//
//  Slick
//  ---------------------------------------------


.slick-slider {
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.slider-arrows{
        padding: 0 40px;
    }
    &.product-items {
        padding: 0;

        @include min-screen($screen__m) {
            padding: 0 $indent__xxl;
        }
    }
    &.slick-initialized {
        opacity: 1;

        + .slick-loading {
            display: none;
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;

    .product-items & {
        @include min-screen($screen__l) {
            margin: 0 $indent__xxl;
        }
    }

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    position: relative;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }
    img {
        display: inline-block;
    }
    &.slick-loading img {

    }
    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: inline-block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

//
//  Slick Loading
//  ---------------------------------------------

.slick-loading,
.widget-loading {
    width: 100%;
    text-align: center;
    margin: $indent__xl 0;
}

//
//  Slick Dots
//  ---------------------------------------------

.slick-dots {
    @include lib-list-reset-styles();
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        margin: 0 $indent__xs;

        button {
            display: block;
            width: 14px;
            height: 14px;
            border: 2px solid #b4b4b4;
            border-radius: 7px;
            background-color: transparent;
            text-indent: -999em;
            overflow: hidden;
            padding: 0;

            &:hover {
                background-color: #b4b4b4;
            }
        }
        &.slick-active {
            button {
                background-color: #b4b4b4;
            }
        }
    }

    a{
        text-align: center;
    }
}

//
//  Slick Arrows
//  ---------------------------------------------

.slick-arrow {
    display: block;
    @include lib-css(transition, opacity 0.15s, 1);
    position: absolute;
    top: 50%;
    margin-top: -24px;
    opacity: 1;
    z-index: 0;
    cursor: pointer;
    font-size: 0;
    border: 0;
    background: none;
    color: $border__color;
    border-radius: 0;
    width: 23px;
    height: 41px;
    text-align: center;
    box-shadow: none;

    &:focus,
    &:hover,
    &:active {
        border: 0;
        //color: $color-black;
        background-color: transparent;
    }
}
.slick-next {
    right: 13px;
    padding: 4px 0 0 2px;
   // background-image: url('../../web/images/slick-right.png');

    //&:before {
        //@extend .fas;
        //content: fa-content($fa-var-angle-right);
        //@include lib-font-size(30px);
   // }
}
.slick-prev {
    left: 13px;
    padding: 4px 2px 0 0;
  //  background-image: url('../../web/images/slick-left.png');

    //&:before {
        //@extend .fas;
        //content: fa-content($fa-var-angle-left);
        //@include lib-font-size(30px);
    //}
}
.slick-disabled {
    opacity: 0.25;
    cursor: default;

    &:hover {
        opacity: 0.25;
    }
}

.navigation-shadow{
    main#maincontent:before {
        opacity: 0.8;
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: black;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1;
    }
}

#main-navigation {
    border-top: 1px solid $primary__color;
    border-bottom: 1px solid $primary__color;
    background:$primary__color;
    clear: both;
    position: relative;
    z-index: 1;

    @include max-screen($screen__l) {
        display:none;
    }

    .navigation {
        display: table;
        list-style: none;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
        background: transparent;
        position: initial;

        //-----------------Top Root Menu
        .top-categoies {
            display: table-cell;
            padding: 0 15px;
            height: 51px;
            margin-bottom: 0;

            & > a {
                display: block;
                text-align: center;
                height: 51px;
                line-height: 51px;
                font-size: 16px;
                cursor: pointer;
                font-weight: 700;
                color:white;

                &[title="Clearance"]{
                    background:#ec1667;
                }

                &:hover{
                    text-decoration: none;
                }
            }
        }

        //-----------------Sub Dropdown Menus
        .submenu {
            background: #fff;
            display: none;
            padding: 0 0 10px 0;
            min-width: 200px;
            position: absolute;
            box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.34);
            list-style: none;
            
            li {
                margin-bottom: 0;
        
                a {
                    display: block;
                    padding: 5px 0px;
                    text-align: left;
                    color: #253b4b;
                    font-family: "Roboto", sans-serif;
                }
            }
        }

        //-----------------standard menu
        .standard-menu {
            padding-bottom: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            li {
                margin-bottom: 0;

                a {
                    display: block;
                    padding: 9px 20px;
                    border-bottom: 1px solid #ddd;
                }
            }
        }

        //-----------------MegaMenu
        .megamenu {
            list-style: none;
            top: 100%;
            left: 0 !important;
            width: 100%;
            padding: 30px 0 50px;
            text-align: left;
            z-index: 3;

            .widget.block{
                margin:0;
            }

            .title {
                padding: 0;
                font-size: 15px;
                text-align: left;
                font-family: "Roboto", sans-serif;
                color: #253b4b;
                border-bottom: 1px solid #f6f4f5;
                padding-bottom: 10px;
                margin-bottom: 10px;

                a{
                    color: $primary__color;
                    padding: 0;
                    font-weight: bold;
                }
            }

            .col-s-4{
                .nav{
                    margin: 0 -15px;

                    a{
                        width: 50%;
                        float: left;
                        padding: 5px 15px;
                    }
                }
            }
            nav {
                a {
                    padding: 5px 0;
                    font-size: 14px;
                    color: $primary__color;
                }
            }

            .widget.block.block-static-block{
                margin: 0px;
            }
        }
    }

    .brands{
     .row > div{
         width: 200px;
         padding: 10px;

         a{
             background: white;
             padding: 20px;
             border: 1px solid #d8d8d8;
             border-radius: 20px;
             font-weight: bold;
             text-align: center;

             &:hover{
                 border: 1px solid #253b4b;
                 text-decoration:none;
             }
             p{
                 font-weight: bold;
                 text-align: center;
             }
             img{
                 max-width: 100%;
             }
         }
     }
}

    .navigation{
        .menu-bannner{
            position:relative;
            background:#f6f4f5;

            p{
                position: absolute;
                color: #e11b66;
                font-weight: 400;
                top: 15px;
                left: 30px;
                line-height: 19px;

                strong{
                    display: block;
                    font-size: 20px;
                }
                span{
                    font-size: 20px;
                    color: #3c4951;
                    font-weight: bold;
                }
            }
            a.action{
                width: auto;
                color: white;
                text-align: center;
                position: absolute;
                padding: 10px 15px;
                bottom: 20px;
                right: 20px;

                &:hover{
                    background:$primary__color;
                    border-color:$primary__color;
                }
            }
        }
    }
}

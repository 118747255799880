.tax-switcher{
  width: 137px;
  display: flex;
  gap: 5px;
  align-items: center;

  @include max-screen($screen__m) {
    display: none;
  }

  span{
    color: #39464f;
    font-size: 12px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    padding-top: 4px;
    flex-wrap: nowrap;
    flex-direction: row;
    white-space: nowrap;
  }
  .switch{
    cursor: pointer;
    display: block;
    position: relative;
    height: 20px;
    width: 37px;
    border-radius: 10px;
    background: #e8e8e8;
    transition: background-color 500ms ease;

    &.active{
      background: #c7ecc7;

      &:after {
        right: 0;
        background-color: #00cc00;
        border: 1px solid #9e9e9e;
        transition: all 500ms ease, right 500ms ease, background-color 500ms ease, box-shadow 500ms ease;
      }
    }

    &:after {
      content: "";
      display: block;
      height: 18px;
      width: 18px;
      position: absolute;
      top: 1px;
      right: 18px;
      border-radius: 50%;
      background-color: #e8e8e8;
      border: 1px solid #9e9e9e;
      transition: all 500ms ease, left 500ms ease, background-color 500ms ease, box-shadow 500ms ease;
    }
  }
  form{
    display: none;
  }
}


[data-label="Exc. VAT"]::after,
[data-label="Inc. VAT"]::after{
  content: attr(data-label);
  font-size: 15px;
  color:#878787;
  margin-left: 10px;
}

.old-price [data-label="Exc. VAT"]::after,
.old-price [data-label="Inc. VAT"]::after{
  display: none;
}

#mobile-menu {
  .tax-switcher {
    padding: 10px;
    width: 200px;

    span{
      color:#fff;
    }
  }
}
